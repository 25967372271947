"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.go = exports.default = void 0;
exports.hasError = hasError;
exports.isDone = isDone;
exports.isSuccess = isSuccess;
var _immutable = require("immutable");
var _data_utils = require("./utils/data_utils");
var l = _interopRequireWildcard(require("./core/index"));
var _index2 = require("./store/index");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _dataFns = (0, _data_utils.dataFns)(['sync']),
  get = _dataFns.get,
  set = _dataFns.set;
var _default = exports.default = function _default(m, key, opts) {
  if (get(m, key) !== undefined) return m;
  var status = opts.waitFn ? 'waiting' : !opts.conditionFn || opts.conditionFn(m) ? 'pending' : 'no';
  return set(m, key, (0, _immutable.Map)({
    conditionFn: opts.conditionFn,
    errorFn: opts.errorFn,
    recoverResult: opts.recoverResult,
    syncStatus: status,
    successFn: opts.successFn,
    syncFn: opts.syncFn,
    timeout: opts.timeout || 6000,
    waitFn: opts.waitFn
  }));
};
var syncStatusKey = function syncStatusKey(key) {
  return (window.Array.isArray(key) ? key : [key]).concat(['syncStatus']);
};
var getStatus = function getStatus(m, key) {
  return get(m, syncStatusKey(key));
};
var setStatus = function setStatus(m, key, str) {
  return set(m, syncStatusKey(key), str);
};
var getProp = function getProp(m, key, name) {
  return get(m, key).get(name);
};
var findKeys = function findKeys(m) {
  return m.reduce(function (r, v, k) {
    var current = _immutable.Map.isMap(v) && v.has('syncStatus') ? [k] : [];
    var nested = _immutable.Map.isMap(v) ? findKeys(v).map(function (x) {
      return [k].concat(x);
    }) : [];
    return r.concat.apply(r, [current].concat([nested]));
  }, []);
};
function removeKeys(m, keys) {
  return keys.reduce(function (r, k) {
    return r.deleteIn(syncStatusKey(k));
  }, m);
}
var process = function process(m, id) {
  var keys = findKeys(get(m, [], (0, _immutable.Map)()));
  // TODO timeout
  return keys.reduce(function (r, k) {
    if (typeof getProp(r, k, 'syncFn') != 'function') return r;
    if (getStatus(r, k) === 'pending') {
      r = setStatus(r, k, 'loading');
      var called = false;
      getProp(r, k, 'syncFn')(r, function (error, result) {
        if (called) return;
        called = true;
        setTimeout(function () {
          (0, _index2.swap)(_index2.updateEntity, 'lock', id, function (m) {
            var errorFn = getProp(r, k, 'errorFn');
            if (error && typeof errorFn === 'function') {
              setTimeout(function () {
                return errorFn(m, error);
              }, 0);
            }
            var recoverResult = getProp(m, k, 'recoverResult');
            if (error && recoverResult === undefined) {
              return handleError(m, k, error);
            } else {
              m = setStatus(m, k, 'ok');
              return getProp(m, k, 'successFn')(m, error ? recoverResult : result);
            }
          });
        }, 0);
      });
    } else if (getStatus(r, k) === 'waiting') {
      if (getProp(r, k, 'waitFn')(r)) {
        var conditionFn = getProp(r, k, 'conditionFn');
        r = setStatus(r, k, !conditionFn || conditionFn(r) ? 'pending' : 'no');
      }
    }
    return r;
  }, m);
};
var go = exports.go = function go(id) {
  (0, _index2.observe)('sync', id, function (m) {
    setTimeout(function () {
      return (0, _index2.swap)(_index2.updateEntity, 'lock', id, process, id);
    }, 0);
  });
};
function isSuccess(m, key) {
  return getStatus(m, key) === 'ok';
}
function isDone(m) {
  var keys = findKeys(get(m, [], (0, _immutable.Map)()));
  return keys.length > 0 && keys.reduce(function (r, k) {
    return r && !isLoading(m, k);
  }, true);
}
function hasError(m) {
  var excludeKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var keys = findKeys(removeKeys(get(m, [], (0, _immutable.Map)()), excludeKeys));
  return keys.length > 0 && keys.reduce(function (r, k) {
    return r || getStatus(m, k) === 'error';
  }, false);
}
function isLoading(m, key) {
  return ['loading', 'pending', 'waiting'].indexOf(getStatus(m, key)) > -1;
}
function handleError(m, key, error) {
  var result = setStatus(m, key, 'error');

  // TODO: this should be configurable for each sync
  if (key !== 'sso') {
    var stopError = new Error("An error occurred when fetching ".concat(key, " data for Lock: ").concat(error.message));
    stopError.code = 'sync';
    stopError.origin = error;
    result = l.stop(result, stopError);
  }
  return result;
}
