import { Link } from "react-router-dom";
import { Text } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";
import { SimpleLink } from "../../../components/rxLibrary/Link";
import { Box } from "../../../components/rxLibrary/box";
import { Card } from "./Card";

export function DailyPurchaseCard() {
  return (
    <Card>
      <div tw="flex flex-col items-center">
        <img tw="mb-[28px]" src="/assets/pages/home/daily-purchase.svg" />

        <Text tw="mb-[18px]" weight="bold" size={21}>
          DAILY PURCHASE
        </Text>

        <Text tw="w-[295px] mb-[9px] mx-auto" size="medium" center>
          Add Rx & inventory items throughout the day and make your
          consolidated, end-of-day orders.
        </Text>

        <Link to="/shopping-list-add-rx">
          <Button>Manage Lists</Button>
        </Link>
      </div>

      <Box
        tw="border-t border-dashed mt-[12px] mx-[-24px] p-[2px]"
        borderColor="grey-2"
      >
        <Box
          tw="text-center rounded-b-lg py-[10px]"
          bgColor="grey-4"
          bgColorOpacity={40}
        >
          <Link to="/shoppingList">
            <SimpleLink size="medium">
              Use the previous version of Daily Purchase
            </SimpleLink>
          </Link>
        </Box>
      </Box>
    </Card>
  );
}
