import React, { useCallback, useEffect, useState } from "react";
import { authService } from "libs/Authentication";
import {
  getAdminSupplierMappingOptions,
  getAdminSupplierMappingSuppliersByPharmacyId,
} from "services/supplierMappings";
import { SelectOption } from "components/rxLibrary/selects/select";
import { OptionLabelWithWarning } from "components/antd/select/OptionLabelWithWarning";
import { AdminSupplierMappingOptions } from "utilities/types";
import {
  isInitialSupplier,
  AdminSupplierMappingPharmacyOption,
} from "./AdminSupplierMappingForm.utils";

export function useAdminSupplierMappingOptions({
  pharmacyId,
  supplierId,
  initialSupplierId,
  initialPharmacyId,
}: {
  pharmacyId?: number;
  supplierId?: number;
  initialSupplierId?: number;
  initialPharmacyId?: number;
}) {
  const [selectedPharmacy, setSelectedPharmacy] =
    useState<AdminSupplierMappingPharmacyOption>();
  const [selectedSupplier, setSelectedSupplier] = useState<SelectOption>();
  useState<AdminSupplierMappingOptions["defaultSupplierShippingConfig"]>();
  const [
    { supplierOptions, pharmacyOptions, defaultSupplierShippingConfig },
    setOptions,
  ] = useState<{
    supplierOptions?: SelectOption[];
    pharmacyOptions?: AdminSupplierMappingPharmacyOption[];
    defaultSupplierShippingConfig?: AdminSupplierMappingOptions["defaultSupplierShippingConfig"];
  }>({});
  const [supplierOptionsWithIsAdded, setSupplierOptionsWithIsAdded] =
    useState<SelectOption[]>();
  const [supplierMappingSupplierIds, setSupplierMappingSupplierIds] =
    useState<number[]>();

  const supplierAlreadyAddedValidator = useCallback(
    async (_: any, supplierId: number) => {
      if (!supplierMappingSupplierIds) return;
      const isInitialSupplierId = isInitialSupplier({
        pharmacyId,
        supplierId,
        initialPharmacyId,
        initialSupplierId,
      });
      if (isInitialSupplierId) return;
      if (supplierMappingSupplierIds?.includes(supplierId)) {
        throw new Error("Supplier already added");
      }
    },
    [
      pharmacyId,
      initialPharmacyId,
      initialSupplierId,
      supplierMappingSupplierIds,
    ]
  );

  useEffect(() => {
    init();

    async function init() {
      try {
        const token = await authService.getAccessTokenSilently();
        const response = await getAdminSupplierMappingOptions(token);
        const supplierMappingOptions = response.data?.supplierMappingOptions;
        if (!supplierMappingOptions) {
          throw new Error("Error fetching no admin supplier mapping options");
        }

        const { supplier, pharmacy } = supplierMappingOptions;
        const newSupplierOptions = supplier.map((v) => ({
          value: v.id,
          label: v.name,
        }));
        const newPharmacyOptions = pharmacy.map((v) => ({
          value: v.id,
          label: v.name,
          timezone: v.timezone,
          pharmacyOrganizationId: v.pharmacyOrganizationId || undefined,
        }));

        setOptions({
          supplierOptions: newSupplierOptions,
          pharmacyOptions: newPharmacyOptions,
          defaultSupplierShippingConfig:
            supplierMappingOptions.defaultSupplierShippingConfig,
        });
      } catch (error) {
        console.error("Error fetching admin supplier mapping options", error);
      }
    }
  }, []);

  useEffect(() => {
    setSupplierOptionsWithIsAdded(undefined);
    init();

    async function init() {
      if (!pharmacyId) return;

      try {
        const token = await authService.getAccessTokenSilently();
        const response = await getAdminSupplierMappingSuppliersByPharmacyId(
          token,
          pharmacyId
        );
        const newSupplierMappingSupplierIds =
          response.data?.supplierMappingSupplierIds;

        if (!newSupplierMappingSupplierIds) {
          throw new Error("Error fetching admin supplier mapping supplier ids");
        }

        setSupplierMappingSupplierIds(newSupplierMappingSupplierIds);
      } catch (error) {
        console.error(
          `Error fetching admin supplier mapping supplier ids by pharmacy id ${pharmacyId}`,
          error
        );
      }
    }
  }, [pharmacyId]);

  useEffect(() => {
    if (!supplierMappingSupplierIds?.length || !supplierOptions) {
      setSupplierOptionsWithIsAdded(undefined);
      return;
    }

    const newSupplierOptions = supplierOptions.map((v) => {
      const isAdded = supplierMappingSupplierIds.includes(v.value);
      if (!isAdded) return { ...v, isAdded: false };

      const isInitialSupplierId = isInitialSupplier({
        pharmacyId,
        supplierId: v.value,
        initialPharmacyId,
        initialSupplierId,
      });

      const warning = isInitialSupplierId
        ? "Initial Supplier"
        : "Already Added";

      return {
        ...v,
        isAdded: !isInitialSupplierId,
        disabled: !isInitialSupplierId,
        label: <OptionLabelWithWarning warning={warning} label={v.label} />,
      };
    });

    setSupplierOptionsWithIsAdded(newSupplierOptions);
  }, [
    initialSupplierId,
    initialPharmacyId,
    supplierOptions,
    supplierMappingSupplierIds,
  ]);

  useEffect(() => {
    const pharmacy = pharmacyOptions?.find((v) => v.value === pharmacyId);
    setSelectedPharmacy(pharmacy);
  }, [pharmacyId, pharmacyOptions]);

  useEffect(() => {
    const supplier = supplierOptions?.find((v) => v.value === supplierId);
    setSelectedSupplier(supplier);
  }, [supplierId, supplierOptions]);

  return {
    pharmacyOptions,
    supplierOptions,
    selectedPharmacy,
    selectedSupplier,
    supplierOptionsWithIsAdded,
    supplierMappingSupplierIds,
    defaultSupplierShippingConfig,
    supplierAlreadyAddedValidator,
  };
}
