import { Switch, Route } from "react-router-dom";
import { EnhancedScreenLockInactivity } from "../../enhanced/EnhancedScreenLockInactivity";
import EnhancedShoppingListSearchTestRoute from "../../enhanced/EnhancedShoppingListSearchTestRoute";
import { PreviousShoppingLists } from "./PreviousShoppingLists";
import { ShoppingList } from "./ShoppingList/ShoppingList";
import { ShoppingListPrint } from "./ShoppingListPrint";
import { ShoppingListThree } from "./ShoppingListThree/ShoppingListThree";
import { ShoppingListTwo } from "./ShoppingListTwo/ShoppingListTwo";
import { ShoppingRx } from "./ShoppingRx/ShoppingRx";

export function Shopping() {
  return (
    <EnhancedScreenLockInactivity>
      <Switch>
        <Route
          path="/previousShoppingLists"
          component={PreviousShoppingLists}
        />

        <Route path="/shoppingList" component={ShoppingList} />

        <Route path="/shopping-list-add-rx" component={ShoppingRx} />

        <Route path="/shoppingListTwo" component={ShoppingListTwo} />

        <Route path="/ShoppingListThree" component={ShoppingListThree} />

        <Route path="/shoppingListPrint" component={ShoppingListPrint} />

        <Route
          path="/testShoppingListEnhance"
          component={EnhancedShoppingListSearchTestRoute}
        />
      </Switch>
    </EnhancedScreenLockInactivity>
  );
}
