import { useEffect, useState, ReactNode } from "react";
import { Auth0Lock } from "auth0-lock";
import { LOGGED_IN_EMAIL_STORAGE_KEY } from "../utilities/localStorage/constants";
import { FullPageLoader } from "../components/loaders/FullPageLoader";

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const BLACKLISTED_PATHS = [
  "/supplierNavigation",
  "/supplierBrowserBar",
  "/supplierSafetyScreen",
];

export let authService: AuthService;

class AuthService {
  #token: string | undefined | null = null;
  #authLock: Auth0LockStatic;
  #setReactIsAuthenticated: (v: boolean) => void;

  constructor(setReactIsAuthenticated: (v: boolean) => void) {
    this.#setReactIsAuthenticated = setReactIsAuthenticated;

    this.#authLock = new Auth0Lock(
      REACT_APP_AUTH0_CLIENT_ID as string,
      REACT_APP_AUTH0_DOMAIN as string,
      {
        auth: {
          responseType: "token id_token",
          redirectUrl: window.location.origin,
          redirect: false,
          audience: REACT_APP_AUTH0_AUDIENCE,
        },
        languageDictionary: {
          title:
            window.location.pathname === "/download"
              ? "Sign in to Download DaylightRx"
              : "Sign in to DaylightRx",
        },
        theme: {
          logo: "https://app.daylightrx.com/assets/daylightrx_logo_132.png",
          primaryColor: "#635dff",
        },
        allowShowPassword: true,
        autoclose: true,
        closable: false,
        prefill: {
          email: localStorage.getItem(LOGGED_IN_EMAIL_STORAGE_KEY) || undefined,
        },
      }
    );

    this.#authLock.on("authenticated", (authResult) => {
      this.#setToken(authResult.idToken);
    });

    this.#refreshToken();
  }

  getAccessTokenSilently = async () => {
    if (
      !this.#token ||
      isTokenExpired(this.#token) ||
      isTokenExpiringWithinTwelveHours(this.#token)
    ) {
      await this.#refreshToken();
    }
    return this.#token as string;
  };

  logout = () => {
    this.#setToken(undefined);
    this.#authLock.logout({ returnTo: window.location.origin });
  };

  #setToken(token: string | undefined) {
    if (token === this.#token) return;

    this.#token = token;
    this.#setReactIsAuthenticated(!!token);
    this.#updateVisibility();
  }

  #updateVisibility() {
    if (isBlacklistedPath()) {
      this.#authLock.hide();
      return;
    }

    if (this.#token) this.#authLock.hide();
    else this.#authLock.show();
  }

  async #refreshToken() {
    return new Promise<void>((resolve) => {
      this.#authLock.checkSession({}, (_, authResult) => {
        const token = authResult?.idToken;
        this.#setToken(token);
        resolve();
      });
    });
  }
}

function isBlacklistedPath(): boolean {
  return BLACKLISTED_PATHS.includes(window.location.pathname);
}

function parseJwt(token: string): any {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function isTokenExpired(token: string): boolean {
  const expiry = parseJwt(token).exp;
  const currTimeSeconds = Math.floor(new Date().getTime() / 1000);
  return currTimeSeconds >= expiry;
}

function isTokenExpiringWithinTwelveHours(token: string): boolean {
  const expiry = parseJwt(token).exp;
  const currTimeSeconds = Math.floor(new Date().getTime() / 1000);
  return currTimeSeconds >= expiry - 12 * 60 * 60;
}

export function AuthenticationProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (!authService) authService = new AuthService(setIsAuthenticated);

    const intervalId = setInterval(authService.getAccessTokenSilently, 60000); // 1 minute
    return () => clearInterval(intervalId);
  }, []);

  if (!isAuthenticated) return <FullPageLoader />;
  return children;
}
