import type {
  ItemPurchaseDetails,
  Prescription,
} from "../../../utilities/types";
import { isItemTypePms } from "../../../utilities/prescriptions/itemType/isItemTypePms";
import { ItemTypeEnum } from "../../../utilities/prescriptions/itemType/itemTypeEnum";

export function getRxNumbersInCartToRemove({
  cart,
  prescriptions,
}: {
  cart: ItemPurchaseDetails[];
  prescriptions: Prescription[];
}) {
  if (!cart.length) return;

  const pmsRxNumbersInPrescriptions = new Set(
    prescriptions
      .filter((prescription) => {
        const isPms = prescription.itemType === ItemTypeEnum.PMS;
        return isPms;
      })
      .map((prescription) => prescription.rxNumber)
  );

  const pmsRxNumbersInCart = cart.filter(isItemTypePms).map((item) => item.id);
  const rxNumbersInCartToRemove = pmsRxNumbersInCart.filter((rxNumber) => {
    return !pmsRxNumbersInPrescriptions.has(rxNumber);
  });

  if (rxNumbersInCartToRemove.length) return rxNumbersInCartToRemove;
}
