import React, {useContext, useEffect, useState, ReactNode} from 'react';
import { Button } from "../components/rxLibrary/buttons";
import EnhancedClientCommandContext from "./EnhancedClientCommandContext";
import EnhancedClientConfigContext, {
} from "./EnhancedClientConfigContext";
import EnhancedClientInactivityContext from "./EnhancedClientInactivityContext";
import { Container } from "../components/containers/Container";
import { useBuyingPharmacy } from "../contexts/BuyingPharmacyContext";
import TimeUntil from "../components/TimeUntil";
import { authService } from "../libs/Authentication";

export function EnhancedScreenLockInactivity({children}: { children: ReactNode }) {
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { appSleepMode, updateShutdownWarnDate } = useContext(EnhancedClientInactivityContext);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { awakenApp, restartApp } = useContext(EnhancedClientCommandContext);
  
  const [currentWakingName, setCurrentWakingName] = useState<string | undefined>(undefined);

  useEffect(() => {
    if(appSleepMode === "waking" && !!currentBuyingPharmacy) {
      const startTime = new Date().getTime();
      const intervalLength = 2000;
      const interval = setInterval(() => {
        const delta: number = new Date().getTime() - startTime;
        const idx: number = Math.floor((delta / intervalLength)) % currentBuyingPharmacy.suppliers.length;
        const supplier = currentBuyingPharmacy.suppliers[idx];
        setCurrentWakingName(supplier?.displayName);
      }, intervalLength);
      return () => {
        clearInterval(interval);
      }
    } else {
      return () => {}
    }
  }, [appSleepMode, currentBuyingPharmacy])

    if (updateShutdownWarnDate) {
        return <Container className="mt-48">
          <div className="text-lg mb-20"><strong>DaylightRx will be shutting down for an update in <TimeUntil when={new Date(updateShutdownWarnDate.getTime() + 5 * 60 * 1000).getTime()} /></strong></div>
          <div tw="flex text-xl">
            <Button variant="secondary" style={{marginRight: "32px"}} onClick={async () => {
              await authService.getAccessTokenSilently();
              awakenApp();
            }}>Cancel Update and Wake Up</Button>
            <Button variant="secondary" onClick={() => {
              restartApp();
            }}>Restart Now</Button>
          </div>
        </Container>
    }

    if (!enhancedClientActive || appSleepMode === "awake") {
        return children as JSX.Element;
    }

    if (appSleepMode === "waking") {
      return <>
        <div>
          <div tw="flex justify-center text-2xl mb-12 mt-16">
            Waking application...this should take 20 seconds.
          </div>
          {currentWakingName && (
            <div tw="flex justify-center text-2xl mb-12 mt-16">
              Waking {currentWakingName}...
            </div>
          )}
        </div>
      </>
    } else {
      return <>
        <div>
          <div tw="flex justify-center text-2xl mb-12 mt-16">
            We noticed you haven&apos;t run a search in a while.
          </div>
          <div tw="flex justify-center text-xl">
            <Button onClick={async () => {
              await authService.getAccessTokenSilently();
              awakenApp();
            }}>Wake Up</Button>
          </div>
        </div>
      </>
    }
}
