import React from "react";
import "twin.macro";
import { CSVLink } from "react-csv";
import { AgGridReact } from "ag-grid-react";
import {
  CSV_HEADERS,
  COLUMN_DEFS,
} from "./Plan.constants";
import { ROW_CLASS_RULES } from "../../utils/reportsConstants";
import { ReportWrapper } from "../../utils/ReportWrapper";
import { useReport } from "../../utils/useReport/useReport";
import { Button } from "../../../../../../components/rxLibrary/buttons";

// function ExportToolPanel({
//   handleExport,
//   exportToolPanelProps: { data },
// }: {
//   handleExport: () => void;
//   exportToolPanelProps: { data: never[] };
// }) {
//   return (
//     <>
//       <Button block fullWidth variant="secondary" onClick={handleExport}>
//         Download CSV
//       </Button>
//
//       <CSVLink data={data} headers={CSV_HEADERS} filename="export-all.csv">
//         <Button block fullWidth variant="secondary">
//           Download CSV of all Parent and Child Grid Fields
//         </Button>
//       </CSVLink>
//     </>
//   );
// }

export function CompetitorPlan({ data }: { data: never[] }) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
    exportToolPanelProps: { data },
    // ExportToolPanel,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        rowGroupPanelShow="always"
      />
    </ReportWrapper>
  );
}
