import { AxiosResponse } from "axios";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { authService } from "../../../libs/Authentication";
import { BackendResponse } from "../../../services/legacy/config/backend-api";
import { displayErrorModal } from "../../../utilities/errorModals/displayErrorModal";
import {
  RequestClient,
  RequestClientConfig,
  useRequestClient,
} from "../../../services/request/requestClient";
import { OnSubmit } from "./useHandleSubmit";

export function useEditFormLegacy<
  T extends Record<string, any>,
  J extends Record<string, any>,
  Name extends string
>({
  name,
  update,
  fetchValues,
}: {
  name: Name;
  update: (
    token: string,
    id: string,
    data: J
  ) => Promise<BackendResponse<Record<Name, T>>>;
  fetchValues: (
    token: string,
    id: string
  ) => Promise<BackendResponse<Record<Name, T>>>;
}) {
  const { id } = useParams<{ id: string }>();
  const [values, setValues] = useState<T>();

  const onSubmit: OnSubmit<J> = useCallback(
    async (formValues) => {
      const token = await authService.getAccessTokenSilently();
      const response = await update(token, id, formValues);

      const newValues = response.data?.[name];
      if (newValues) setValues(newValues);

      return { response };
    },
    [id, name]
  );

  useEffect(() => {
    init();

    async function init() {
      try {
        const token = await authService.getAccessTokenSilently();
        const response = await fetchValues(token, id);
        const newValues = response.data?.[name];
        if (!newValues) throw new Error("Invalid request response");

        setValues(newValues);
      } catch (error) {
        const title = "Error fetching page values";
        console.error(title, error);
        displayErrorModal({ title });
      }
    }
  }, [id, name]);

  return { values, onSubmit };
}

export function useEditForm<
  I extends Record<string, any> & { id: number },
  O extends Record<string, any>,
  Name extends string
>({
  name,
  update,
  fetchValues,
}: {
  name: Name;
  update: (
    client: RequestClient,
    data: RequestClientConfig<I>
  ) => Promise<void | AxiosResponse<{ data: Record<Name, O> }>>;
  fetchValues: (
    client: RequestClient,
    data: RequestClientConfig<{ id: string }>
  ) => Promise<void | AxiosResponse<{ data: Record<Name, O> }>>;
}) {
  const requestClient = useRequestClient();
  const { id } = useParams<{ id: string }>();
  const [values, setValues] = useState<O>();

  const onSubmit: OnSubmit<I> = useCallback(
    async (formValues) => {
      let formattedResponse;

      try {
        const response = await update(requestClient, {
          ...formValues,
          id: Number(id),
        });

        const newValues = response?.data?.data?.[name];
        if (newValues) setValues(newValues);

        const data = response?.data?.data;
        if (data) formattedResponse = { data };
        else formattedResponse = { error: true };
      } catch (error: any) {
        formattedResponse = error?.response?.data;
      }

      return { response: formattedResponse };
    },
    [id, name, requestClient]
  );

  useEffect(() => {
    init();

    async function init() {
      try {
        const response = await fetchValues(requestClient, { id });
        const newValues = response?.data?.data?.[name];
        if (!newValues) throw new Error("Invalid request response");

        setValues(newValues);
      } catch (error) {
        const title = "Error fetching page values";
        console.error(title, error);
        displayErrorModal({ title });
      }
    }
  }, [id, name, requestClient]);

  return { values, onSubmit };
}
