"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailDomain = emailDomain;
exports.emailLocalPart = emailLocalPart;
exports.isEmail = isEmail;
exports.setEmail = setEmail;
exports.validateEmail = validateEmail;
var _trim = _interopRequireDefault(require("trim"));
var _isEmail2 = _interopRequireDefault(require("validator/lib/isEmail"));
var _index = require("./index");
var _enterprise = require("../connection/enterprise");
var i18n = _interopRequireWildcard(require("../i18n"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function validateEmail(str) {
  var strictValidation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return isEmail(str, strictValidation);
}
function isEmail(str) {
  var strictValidation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (typeof str !== 'string') {
    return false;
  }
  var trimmed = (0, _trim.default)(str);
  return strictValidation ? (0, _isEmail2.default)(str) : trimmed.indexOf('@') >= 0 && trimmed.indexOf('.') >= 0 && trimmed.indexOf(' ') === -1;
}
function setEmail(m, str) {
  var strictValidation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return (0, _index.setField)(m, 'email', str, function (str) {
    var validHRDEMail = (0, _enterprise.isHRDEmailValid)(m, str);
    return {
      valid: validateEmail(str, strictValidation) && validHRDEMail,
      hint: !validHRDEMail ? i18n.html(m, ['error', 'login', 'hrd.not_matching_email']) : undefined
    };
  });
}
function emailDomain(str) {
  if (!isEmail(str)) {
    return '';
  }
  return str.split('@')[1].toLowerCase();
}
function emailLocalPart(str) {
  var domain = emailDomain(str);
  return domain ? str.slice(0, -1 - domain.length) : str;
}
