import React, { useRef, useState } from "react";
import pluralize from "pluralize";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import cn from "classnames";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import {
  getDispensesStatsGroup,
  getPackSizeList,
  getQuantity,
} from "../../../utilities/drugInfo/drugInfo";
import { ItemInCart, DrugWithStats } from "../../../utilities/types";
import { formatDrugDispenseQuantity } from "../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { getInitialManufacturer } from "../../../utilities/prescriptions/getInitialManufacturer";
import { getInitialPackSize } from "../../../utilities/prescriptions/getInitialPackSize";
import { getDirectDeal } from "../../../utilities/prescriptions/getDirectDeal";
import { PrescriptionTags } from "../PrescriptionTags/PrescriptionTags";
import { Switch } from "../../rxLibrary/switch";

export function InventoryModalPopoverSwitch({
  prescription,
  packQuantity,
  callBack,
  prescriptions,
  data,
  manufacturer,
  type,
}: {
  prescription: DrugWithStats;
  packQuantity: number;
  callBack: (prescription: ItemInCart) => void;
  prescriptions?: DrugWithStats[];
  data?: DrugWithStats[] | null;
  manufacturer?: string;
  type?: string;
}) {
  const id = prescription.drug.ndc;
  const noManufacturerPreference = manufacturer === "any";
  const hasDirectDeal = !!getDirectDeal(prescription);

  const refBtn = useRef<HTMLAnchorElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [checkAlert, setCheckAlert] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [btnLabel, setBtnLabel] = useState("Select");
  const [packSize, setPackSize] = useState(() => {
    return getInitialPackSize(prescription);
  });
  const [manufactutrer, setManufactutrer] = useState(() => {
    if (noManufacturerPreference) return true;
    return getInitialManufacturer(prescription);
  });

  const startAlertFlow = (prescriptionObj: ItemInCart) => {
    const drug = prescriptionObj.drug;
    const unitSize = drug.unitSize;
    const unitQuantity = drug.unitQuantity;
    const order = unitSize * unitQuantity * packQuantity;

    setAlert(true);
    const msg = `You have requested ${packQuantity} pack${
      order > 1 ? "s" : ""
    } of ${formatDrugDispenseQuantity(
      drug
    )}. This is more than the ${get30DayUnitsInfo(prescriptions)} dispensed in the last 30 days. Are you sure you want this amount?`;
    setAlertMsg(msg);
  };

  const prescriptionObj: ItemInCart = {
    dawCode: null,
    ...prescription,
    key: `c${new Date().getTime().toString()}`,
    fillDate: new Date().toISOString(),
    packQuantity,
    packSize,
    manufactutrer,
    status: "list",
    noManufacturerPreference,
  };

  const handleClick = () => {
    const node = refBtn.current;
    if (node && node.classList.contains("btn-disabled")) return;

    if (!open) {
      setBtnLabel("Add to List");
      setOpen(!open);
      if (
        type !== "" &&
        checkAlert &&
        validateQty({
          data,
          packQuantity,
          prescriptions,
          prescriptionObj,
        })
      ) {
        startAlertFlow(prescriptionObj);
      }
    } else {
      setBtnLabel("Adding...");
      setTimeout(() => {
        callBack && callBack(prescriptionObj);
      }, 0);
    }
  };

  const handleClickAway = () => {
    setCheckAlert(true);
    setAlert(false);
    setAlertMsg("");
    setOpen(false);
  };

  const alertContinue = () => {
    setAlert(false);
    setCheckAlert(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ position: "relative" }}>
          <a
            href="#"
            className={cn("btn btn-sm justify-center btn-white", {
              "bg-white": !open,
              "btn-primayBlue": open && !alert,
              "btn-disabled": open && alert,
            })}
            aria-describedby={id}
            ref={refBtn}
            onClick={handleClick}
            style={{ minWidth: "120px" }}
          >
            {btnLabel}
          </a>
          <Popper
            className="substitute-pop"
            id={id}
            open={open}
            anchorEl={refBtn.current}
            placement="top-end"
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, 15],
                },
              },
              {
                name: "arrow",
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
            ]}
            style={{ zIndex: 9999 }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <span className="arrow" ref={setArrowRef} />
            <Paper>
              <div className="bg-white">
                {!alert ? (
                  <>
                    {!hasDirectDeal && (
                      <p className="title">Substitute for better pricing</p>
                    )}

                    <div>
                      <label className="flex items-center">
                        <Switch checked={packSize} onChange={setPackSize} />
                        <span className="ml-4 hover-pointer">
                          Allow pack size substitution
                        </span>
                      </label>

                      {noManufacturerPreference ? (
                        <span className="text-sm">
                          No manufacturer preference
                        </span>
                      ) : (
                        <label className="flex items-center">
                          <Switch
                            checked={manufactutrer}
                            onChange={setManufactutrer}
                          />
                          <span className="ml-4 hover-pointer">
                            Allow manufacturer substitution
                          </span>
                        </label>
                      )}

                    </div>
                    <div>
                      <PrescriptionTags
                        item={prescriptionObj}
                        packQuantity={packQuantity}
                        isInventory
                      />
                    </div>
                  </>
                ) : (
                  <div style={{ maxWidth: "340px" }}>
                    <p className="text-sm">{alertMsg}</p>
                    <div className="flex mt-24 justify-between">
                      <a
                        onClick={handleClickAway}
                        href="#"
                        className="btn btn-sm btn-white"
                      >
                        Cancel
                      </a>
                      <a
                        onClick={alertContinue}
                        href="#"
                        className="btn btn-sm btn-primayBlue"
                      >
                        Yes, I&rsquo;m sure
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
}

function get30DayRx(prescriptions?: DrugWithStats[]) {
  if (!prescriptions) return 0;

  let rxNum = 0;
  prescriptions.forEach((item) => {
    const states = getDispensesStatsGroup(item, "last30Days");
    rxNum += states.numberOfUnits;
  });
  return rxNum;
}

function get30DayUnitsInfo(prescriptions?: DrugWithStats[]) {
  if (!prescriptions) return "";

  let UnitsNum = 0;
  let unit = "";

  prescriptions.forEach((item) => {
    const states = getDispensesStatsGroup(item, "last30Days");
    UnitsNum += states.numberOfUnits;
  });

  if (prescriptions[0].drug.uom === "Each") {
    unit = prescriptions[0].drug.form;
  } else if (prescriptions[0].drug.packageDescription === "Box") {
    if (prescriptions[0].drug.form === "Granules") {
      unit = "Box";
    } else {
      unit = prescriptions[0].drug.form;
    }
  } else {
    unit = prescriptions[0].drug.packageDescription;
  }

  const msg = `${UnitsNum.toString().replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  )} ${pluralize(unit, UnitsNum)}`;

  return msg;
}

function validateQty({
  data,
  packQuantity,
  prescriptions,
  prescriptionObj,
}: {
  data?: DrugWithStats[] | null;
  packQuantity: number;
  prescriptions?: DrugWithStats[];
  prescriptionObj: ItemInCart;
}) {
  const drug = prescriptionObj.drug;
  const unitSize = drug.unitSize;
  const unitQuantity = drug.unitQuantity;
  const order = unitSize * unitQuantity * packQuantity;
  const rx = get30DayRx(prescriptions);
  const objPk = getQuantity(drug);
  let minPk = "0";
  let state = false;

  const pkList = data ? getPackSizeList(data) : [];
  if (pkList.length > 0) {
    minPk = pkList[0].value ? (pkList[0].value as string) : "0";
  }

  if (order > rx) {
    if (parseInt(objPk) > parseInt(minPk)) {
      state = true;
    }
  }

  return state;
}
