import type { DirectDeal } from "../../../utilities/types";
import { getDirectDeal } from "../../../utilities/prescriptions/getDirectDeal";
import { roundDirectDealDiscountPercentage } from "../../../utilities/prescriptions/roundDirectDealDiscountPercentage";

export function geDiscountPercentage(item: {
  directDeals?: DirectDeal[];
  packQuantity?: number;
  noManufacturerPreference?: boolean;
}) {
  const { packQuantity, noManufacturerPreference } = item;
  const directDeal = getDirectDeal(item);
  if (!directDeal || noManufacturerPreference || packQuantity === 0) {
    return;
  }

  const { bundleSize, discountPercent } = directDeal;
  if (packQuantity && packQuantity % bundleSize !== 0) return;

  const formattedDiscountPercent =
    roundDirectDealDiscountPercentage(discountPercent);
  return formattedDiscountPercent;
}
