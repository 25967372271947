import React, { useEffect, useState } from "react";
import merge from "lodash/merge";
import { authService } from "../../../libs/Authentication";
import { WideContainer } from "../../../components/containers/WideContainer";

const { REACT_APP_DATA_PIPELINE_API_URL: root } = process.env;

async function dataPipelineApiDoc(options = {}) {
  return await fetch(
    `${root}/docs`,
    merge({ method: "GET", mode: "cors" }, options) as RequestInit
  ).then((response) => response.blob());
}

export function AdminEdiDocPage() {
  const [blobObject, setBlobObject] = useState("");

  useEffect(() => {
    (async () => {
      const token = await authService.getAccessTokenSilently();
      const response = await dataPipelineApiDoc({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = new Blob([response], { type: "text/html" });
      const obj = URL.createObjectURL(blob);
      setBlobObject(obj);
    })();
  }, []);

  return (
    <WideContainer tw="pb-[200px]">
      <iframe
        style={{ width: "100%", height: "calc(100vh - 120px)" }}
        src={blobObject}
      />
    </WideContainer>
  );
}
