"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Flow = void 0;
exports.getCaptchaConfig = getCaptchaConfig;
exports.setCaptchaParams = setCaptchaParams;
exports.showMissingCaptcha = showMissingCaptcha;
exports.swapCaptcha = swapCaptcha;
var l = _interopRequireWildcard(require("../core/index"));
var c = _interopRequireWildcard(require("../field/index"));
var i18n = _interopRequireWildcard(require("../i18n"));
var _index3 = require("../store/index");
var _web_api = _interopRequireDefault(require("../core/web_api"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Flow = exports.Flow = Object.freeze({
  DEFAULT: 'default',
  PASSWORDLESS: 'passwordless',
  PASSWORD_RESET: 'password_reset'
});

/**
 * Return the captcha config object based on the type of flow.
 * 
 * @param {Object} m model
 * @param {Flow} flow Which flow the captcha is being rendered in
 */
function getCaptchaConfig(m, flow) {
  if (flow === Flow.PASSWORD_RESET) {
    return l.passwordResetCaptcha(m);
  } else if (flow === Flow.PASSWORDLESS) {
    return l.passwordlessCaptcha(m);
  } else {
    return l.captcha(m);
  }
}

/**
 * Display the error message of missing captcha in the header of lock.
 *
 * @param {Object} m model
 * @param {Number} id
 * @param {Flow} flow Which flow the captcha is being rendered in
 */
function showMissingCaptcha(m, id) {
  var flow = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Flow.DEFAULT;
  var captchaConfig = getCaptchaConfig(m, flow);
  var captchaError = captchaConfig.get('provider') === 'recaptcha_v2' || captchaConfig.get('provider') === 'recaptcha_enterprise' || captchaConfig.get('provider') === 'hcaptcha' || captchaConfig.get('provider') === 'auth0_v2' || captchaConfig.get('provider') === 'friendly_captcha' || captchaConfig.get('provider') === 'arkose' ? 'invalid_recaptcha' : 'invalid_captcha';
  var errorMessage = i18n.html(m, ['error', 'login', captchaError]);
  (0, _index3.swap)(_index3.updateEntity, 'lock', id, function (m) {
    m = l.setSubmitting(m, false, errorMessage);
    return c.showInvalidField(m, 'captcha');
  });
  return m;
}

/**
 * Set the captcha value in the fields object before sending the request.
 *
 * @param {Object} m model
 * @param {Object} params
 * @param {Flow} flow Which flow the captcha is being rendered in
 * @param {Object} fields
 *
 * @returns {Boolean} returns true if is required and missing the response from the user
 */
function setCaptchaParams(m, params, flow, fields) {
  var captchaConfig = getCaptchaConfig(m, flow);
  var isCaptchaRequired = captchaConfig && captchaConfig.get('required');
  if (!isCaptchaRequired) {
    return true;
  }
  var captcha = c.getFieldValue(m, 'captcha');
  // captcha required and missing
  if (!captcha) {
    return false;
  }
  params['captcha'] = captcha;
  fields.push('captcha');
  return true;
}

/**
 * Get a new challenge and display the new captcha image.
 *
 * @param {number} id The id of the Lock instance.
 * @param {Flow} flow Which flow the captcha is being rendered in.
 * @param {boolean} wasInvalid A boolean indicating if the previous captcha was invalid.
 * @param {Function} [next] A callback.
 */
function swapCaptcha(id, flow, wasInvalid, next) {
  if (flow === Flow.PASSWORD_RESET) {
    return _web_api.default.getPasswordResetChallenge(id, function (err, newCaptcha) {
      if (!err && newCaptcha) {
        (0, _index3.swap)(_index3.updateEntity, 'lock', id, l.setPasswordResetCaptcha, newCaptcha, wasInvalid);
      }
      if (next) {
        next();
      }
    });
  } else if (flow === Flow.PASSWORDLESS) {
    return _web_api.default.getPasswordlessChallenge(id, function (err, newCaptcha) {
      if (!err && newCaptcha) {
        (0, _index3.swap)(_index3.updateEntity, 'lock', id, l.setPasswordlessCaptcha, newCaptcha, wasInvalid);
      }
      if (next) {
        next();
      }
    });
  } else {
    return _web_api.default.getChallenge(id, function (err, newCaptcha) {
      if (!err && newCaptcha) {
        (0, _index3.swap)(_index3.updateEntity, 'lock', id, l.setCaptcha, newCaptcha, wasInvalid);
      }
      if (next) {
        next();
      }
    });
  }
}
