import {
  DROPDOWN_QUANTITY_LIST_ITEMS,
  getQtyList,
} from "../../components/DropDownListQty";

/** @deprecated use Rx Library -> QtySelect */
export function formatBundleSizeDropdownItems(config?: {
  bundleSize?: number;
  includeZero?: boolean;
  zeroLabel?: string;
  multiplesOfBundleSize?: boolean;
  directDealLabel?: boolean;
}) {
  const {
    zeroLabel,
    bundleSize,
    includeZero,
    directDealLabel,
    multiplesOfBundleSize,
  } = config ?? {};

  let items = includeZero
    ? getQtyList(includeZero, zeroLabel)
    : DROPDOWN_QUANTITY_LIST_ITEMS;

  if (!bundleSize) return items;

  if (multiplesOfBundleSize) {
    items = items.filter((item) => {
      const value = Number(item.value);
      if (value === 0) return true;
      return value % bundleSize === 0;
    });
  }

  if (directDealLabel) {
    items = items.map((item) => {
      const value = Number(item.value);
      if (value === 0 || value % bundleSize !== 0 || bundleSize === 1) {
        return item;
      }
      return { ...item, label: `${item.label} Deal` };
    });
  }

  return items;
}
