import React, { useCallback, useMemo } from "react";
import { DropDownListQty } from "../../../../../components/DropDownListQty";
import {
  PurchaseQuantityMethodEnum,
  PrescriptionQuantityItem,
} from "../../../../../utilities/types";
import { uomDescription } from "../../../../../utilities/helpers";
import { formatWithUnit } from "../../../../../utilities/formatWithUnit";
import { normalizePackQuantity } from "../../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { formatBundleSizeDropdownItems } from "../../../../../utilities/prescriptions/formatDirectDealDropdownItems.deprecated";
import { getDirectDeal } from "../../../../../utilities/prescriptions/getDirectDeal";

export function PurchaseQuantityOptionalOption({
  prescription,
  updatePurchaseQuantity,
}: {
  prescription: PrescriptionQuantityItem;
  updatePurchaseQuantity: (
    rxNumbers: string[],
    purchaseQuantityMethod: PurchaseQuantityMethodEnum,
    num?: string
  ) => void;
}) {
  const {
    drug,
    rxNumbers,
    numPackages = 0,
    useQuantityInput,
  } = prescription;
  const directDeal = getDirectDeal(prescription);

  const units = useMemo(() => {
    const { unitSize, unitQuantity, uom } = drug;
    return formatWithUnit(numPackages * unitSize * unitQuantity, uom);
  }, [drug, numPackages]);

  const uom = useMemo(() => {
    return uomDescription(0, drug, true);
  }, [drug]);

  const packQuantity = useMemo(() => {
    return normalizePackQuantity(numPackages).toString();
  }, [numPackages]);

  const dropdownItems = useMemo(() => {
    return formatBundleSizeDropdownItems({
      bundleSize: directDeal?.bundleSize,
      directDealLabel: true,
      includeZero: true,
    });
  }, [directDeal]);

  const handleOptionalPurchaseQuantityChange = useCallback(
    (newQuantity: string) => {
      updatePurchaseQuantity(
        rxNumbers,
        PurchaseQuantityMethodEnum.Optional,
        newQuantity
      );
    },
    [rxNumbers, updatePurchaseQuantity]
  );

  if (useQuantityInput) {
    return (
      <div>
        <p>Specify # of Packs</p>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <p className="nowrap">{uom} x Qty:</p>

      <DropDownListQty
        value={packQuantity}
        callBack={handleOptionalPurchaseQuantityChange}
        dropDownItems={dropdownItems}
        standOutValue="0"
        className="purchase-quantity__dropdown"
      />

      <div className="text-center flex">
        <p className="total-num">= {units}</p>
      </div>
    </div>
  );
}
