"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.debouncedRequestAvatar = void 0;
exports.requestAvatar = requestAvatar;
var _index = require("./store/index");
var _data_utils = require("./utils/data_utils");
var preload = _interopRequireWildcard(require("./utils/preload_utils"));
var f = _interopRequireWildcard(require("./utils/fn_utils"));
var l = _interopRequireWildcard(require("./core/index"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _dataFns = (0, _data_utils.dataFns)(['avatar']),
  tget = _dataFns.tget,
  tset = _dataFns.tset;
var cache = {};
function requestAvatar(id, src) {
  if (cache[src]) {
    return update(id, src, cache[src].url, cache[src].displayName, true);
  }
  var provider = l.ui.avatarProvider((0, _index.read)(_index.getEntity, 'lock', id)).toJS();
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = tset(m, 'syncStatus', 'loading');
    m = tset(m, 'src', src);
    return m;
  });
  var url, displayName;
  provider.url(src, function (error, str) {
    if (error) return handleError(id, src);
    preload.img(str, function (error, img) {
      if (error) return handleError(id, src);
      url = img.src;
      if (displayName !== undefined) handleSuccess(id, src, url, displayName);
    });
  });
  provider.displayName(src, function (error, str) {
    if (error) return handleError(id);
    displayName = str;
    if (url !== undefined) handleSuccess(id, src, url, displayName);
  });
}
var debouncedRequestAvatar = exports.debouncedRequestAvatar = f.debounce(requestAvatar, 300);
function handleSuccess(id, src, url, displayName) {
  cache[src] = {
    url: url,
    displayName: displayName
  };
  update(id, src, url, displayName);
}
function update(id, src, url, displayName) {
  var force = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    if (force || tget(m, 'src') === src) {
      m = tset(m, 'syncStatus', 'ok');
      m = tset(m, 'url', url);
      m = tset(m, 'src', src);
      m = tset(m, 'displayName', displayName);
    }
    return m;
  });
}
function handleError(id, src) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    return tget(m, 'src') === 'src' ? tset(m, 'syncStatus', 'error') : m;
  });
}
