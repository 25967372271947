import { useEffect, useState } from "react";
import { authService } from "../../../../../../libs/Authentication";
import { SelectOption } from "../../../../../../components/rxLibrary/selects/select";
import { getAdminPharmacyEmailSubscriptionOptions } from "../../../../../../services/legacy/pharmacyEmailSubscriptions";

export function useAdminPharmacyEmailSubscriptionOptions() {
  const [pharmacyOptions, setPharmacyOptions] = useState<SelectOption[]>();

  useEffect(() => {
    init();

    async function init() {
      try {
        const token = await authService.getAccessTokenSilently();
        const response = await getAdminPharmacyEmailSubscriptionOptions(token);
        const supplierMappingOptions = response.data?.emailSubscriptionOptions;
        if (!supplierMappingOptions) {
          throw new Error(
            "Error fetching no admin pharmacy email subscription options"
          );
        }

        const { pharmacy } = supplierMappingOptions;
        const newPharmacyOptions = pharmacy.map((v) => ({
          value: v.id,
          label: v.name,
        }));

        setPharmacyOptions(newPharmacyOptions);
      } catch (error) {
        console.error(
          "Error fetching admin pharmacy email subscription options",
          error
        );
      }
    }
  }, []);

  return { pharmacyOptions };
}
