"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchClientSettings = fetchClientSettings;
exports.syncClientSettingsSuccess = syncClientSettingsSuccess;
var _urlJoin = _interopRequireDefault(require("url-join"));
var _cdn_utils = require("../../utils/cdn_utils");
var l = _interopRequireWildcard(require("../index"));
var _index2 = require("./index");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function fetchClientSettings(clientID, clientBaseUrl, cb) {
  (0, _cdn_utils.load)({
    method: 'setClient',
    url: (0, _urlJoin.default)(clientBaseUrl, 'client', "".concat(clientID, ".js?t").concat(+new Date())),
    check: function check(o) {
      return o && o.id === clientID;
    },
    cb: cb
  });
}
function syncClientSettingsSuccess(m, result) {
  m = (0, _index2.initClient)(m, result);
  m = l.filterConnections(m);
  m = l.runHook(m, 'didReceiveClientSettings');
  return m;
}
