import { useRef, useState, useEffect, useCallback } from "react";
import { Form, InputRef } from "antd";
import * as Sentry from "@sentry/react";
import type { ItemInCart, ItemInModal } from "../../../../utilities/types";
import { insertArray } from "../../../../utilities/arrays/insertArray";
import { getInitialManufacturer } from "../../../../utilities/prescriptions/getInitialManufacturer";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { ItemTypeEnum } from "../../../../utilities/prescriptions/itemType/itemTypeEnum";
import { findAndRemoveFromArray } from "../../../../utilities/arrays/findAndRemoveFromArray";
import { getInitialPackSize } from "../../../../utilities/prescriptions/getInitialPackSize";
import { isNumericString } from "../../../../utilities/strings/isNumericString";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { useRequestClient } from "../../../../services/request/requestClient";
import { getRxes } from "../../../../services/prescriptions";
import { Box } from "../../../../components/rxLibrary/box";
import { Button } from "../../../../components/rxLibrary/buttons";
import { Text } from "../../../../components/rxLibrary/typography";
import { IconCheck, IconMagnify } from "../../../../components/rxLibrary/icons";
import { AutoCompleteInputSearch } from "../../../../components/antd/AutoComplete";
import { Modal } from "../../../../components/rxLibrary/modals";
import { ShoppingRxModalPrescriptionItem } from "../shoppingRxItems/ShoppingRxModalPrescriptionItem";
import { useShoppingRx } from "../useShoppingRx/useShoppingRx";
import { ADD_RX_MOVE_DOWN_ANIMATION_CSS } from "../ShoppingRx.utils";
import { ShoppingRxPrescriptionModalTooltipError } from "./ShoppingRxPrescriptionModalTooltipError";
import { ShoppingRxPrescriptionModalTooltipErrorEnum } from "./ShoppingRxPrescriptionModal.constants";

export function ShoppingRxPrescriptionModal({
  isPrescriptionsLoading,
  close,
  addPrescriptionsToCart,
}: {
  isPrescriptionsLoading: boolean;
  close: () => void;
  addPrescriptionsToCart: (items: ItemInCart[]) => void;
}) {
  const [animation, setAnimation] = useState(false);
  const requestClient = useRequestClient();
  const { prescriptionsItems } = useShoppingRx();
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();

  const [form] = Form.useForm();
  const searchRef = useRef<InputRef>(null);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [tooltipError, setTooltipError] = useState<string>();
  const [itemsInModal, setItemsInModal] = useState<ItemInModal[]>([]);

  const itemsCount = itemsInModal.length;
  const isAddButtonErrorOpen =
    tooltipError === ShoppingRxPrescriptionModalTooltipErrorEnum.ADDING;
  const isSearchErrorOpen = !!tooltipError && !isAddButtonErrorOpen;
  const isLoading = isModalLoading || isPrescriptionsLoading;
  const isAddButtonDisabled = !itemsCount || isAddButtonErrorOpen || isLoading;

  const focusSearchBar = useCallback(() => {
    if (!tooltipError) searchRef.current?.focus();
  }, [tooltipError]);

  const clearSearch = useCallback(() => {
    form.setFieldValue("search", "");
  }, [form]);

  const removeModalItem = useCallback(
    (rxNumber: string) => {
      const newItems = itemsInModal.filter(
        (item) => item.rxNumber !== rxNumber
      );
      setItemsInModal(newItems);
    },
    [itemsInModal]
  );

  const updateModalItem = useCallback(
    (newItem: ItemInModal) => {
      const [filteredItems, _, index] = findAndRemoveFromArray(
        itemsInModal,
        (item) => item.rxNumber === newItem.rxNumber
      );
      const newItems = insertArray(filteredItems, newItem, index);
      setItemsInModal(newItems);
    },
    [itemsInModal]
  );

  const handleAddItemsToCart = useCallback(async () => {
    setIsModalLoading(true);
    try {
      addPrescriptionsToCart(itemsInModal);
      close();
    } catch (error) {
      setTooltipError(ShoppingRxPrescriptionModalTooltipErrorEnum.ADDING);
      setIsModalLoading(false);
    }
  }, [itemsInModal, close, addPrescriptionsToCart]);

  const handleAddItemsToModal = useCallback(
    async (values: { search?: string }) => {
      const rx = values.search;
      if (!pharmacyId || !rx) return;

      setIsModalLoading(true);
      try {
        const response = await getRxes(requestClient, { rx, pharmacyId });
        const { error, prescriptions } = response?.data?.data ?? {};

        if (error || !prescriptions?.length) {
          let msg;
          if (
            error?.message === "Rx not found" &&
            rx.length >= 10 &&
            rx.length <= 15 &&
            isNumericString(rx)
          ) {
            msg = ShoppingRxPrescriptionModalTooltipErrorEnum.NO_RX_NUMBER;
          }
          throw new Error(msg);
        }

        const rxInCartSet = new Set(prescriptionsItems.map(getPrescriptionId));
        const rxInModalSet = new Set(itemsInModal.map((item) => item.rxNumber));
        const newItems = [...itemsInModal];
        prescriptions.forEach((prescription) => {
          const { rxNumber } = prescription;
          if (rxInModalSet.has(rxNumber) || rxInCartSet.has(rxNumber)) {
            throw new Error(
              ShoppingRxPrescriptionModalTooltipErrorEnum.DUPLICATED
            );
          }

          const newItem: ItemInModal = {
            ...prescription,
            eoh: 0,
            boh: 0,
            status: "list",
            itemType: ItemTypeEnum.NoPMS,
            packSize: getInitialPackSize(prescription),
            manufactutrer: getInitialManufacturer(prescription),
            addedAt: new Date().toLocaleString("en-US"),
          };
          newItems.unshift(newItem);
        });

        form.setFieldValue("search", "");
        setItemsInModal(newItems);
        setAnimation(true);
        Sentry.captureMessage(
          "Successfully added prescriptions to add rx modal",
          {
            level: "info",
            extra: { rx, pharmacyId, prescriptions },
          }
        );
      } catch (error: any) {
        const errorMsg =
          error?.message ||
          ShoppingRxPrescriptionModalTooltipErrorEnum.SEARCHING;
        setTooltipError(errorMsg);
        Sentry.captureMessage(
          `Failed to add prescriptions to add rx modal: ${errorMsg}`,
          {
            level: "info",
            extra: { rx, pharmacyId, error },
          }
        );
      } finally {
        setIsModalLoading(false);
      }
    },
    [form, pharmacyId, itemsInModal, prescriptionsItems, requestClient]
  );

  useEffect(() => {
    if (!tooltipError) focusSearchBar();
  }, [tooltipError, focusSearchBar]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAnimation(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [animation]);

  return (
    <Modal onCancel={focusSearchBar} open>
      <div onClick={focusSearchBar} tw="relative flex flex-col h-full">
        <Box
          tw="relative z-10 flex items-center justify-between px-[48px] mb-[24px] pt-[60px]"
          bgColor="white-1"
        >
          <div tw="flex items-center space-x-[8px]">
            <img
              tw="w-[48px]"
              src="/assets/shopping/prescription-icon.png"
              alt="shopping list"
            />
            <Text size={20}>
              Add Items By Rx# {!!itemsCount && `(${itemsCount})`}
            </Text>
          </div>

          <div tw="flex items-center space-x-[6px]">
            <Text size="medium" color="grey-1">
              Rx# to add
            </Text>

            <ShoppingRxPrescriptionModalTooltipError
              onClose={clearSearch}
              isOpen={isSearchErrorOpen}
              errorType={tooltipError}
              setTooltipError={setTooltipError}
            >
              <Form
                form={form}
                initialValues={{ search: "" }}
                onFinish={handleAddItemsToModal}
              >
                <Form.Item name="search" noStyle>
                  <AutoCompleteInputSearch
                    ref={searchRef}
                    tw="w-[188px]"
                    type="text"
                    size="large"
                    placeholder="Rx#"
                    enterButton={<IconMagnify />}
                    disabled={!pharmacyId}
                    allowClear
                  />
                </Form.Item>
              </Form>
            </ShoppingRxPrescriptionModalTooltipError>
          </div>
        </Box>

        <div
          tw="flex-1 overflow-y-scroll px-[48px] space-y-[10px] pt-[1px] pb-[25px]"
          css={!!animation && ADD_RX_MOVE_DOWN_ANIMATION_CSS}
        >
          {itemsInModal.map((item) => (
            <ShoppingRxModalPrescriptionItem
              key={item.rxNumber}
              item={item}
              updateModalItem={updateModalItem}
              removeModalItem={removeModalItem}
            />
          ))}
        </div>

        <div tw="relative flex items-center justify-end space-x-[19px] px-[48px] h-[80px] shadow-[0_-2px_8px_0_#00000029]">
          <Button variant="text-1" onClick={close}>
            Cancel
          </Button>

          <ShoppingRxPrescriptionModalTooltipError
            onClose={clearSearch}
            isOpen={isAddButtonErrorOpen}
            errorType={tooltipError}
            setTooltipError={setTooltipError}
          >
            <Button
              icon={<IconCheck />}
              disabled={isAddButtonDisabled}
              onClick={handleAddItemsToCart}
            >
              Add ({itemsCount}) to list
            </Button>
          </ShoppingRxPrescriptionModalTooltipError>
        </div>

        {isLoading && (
          <Box
            tw="absolute top-0 left-0 h-full w-full flex items-center justify-center"
            bgColor="white-1"
            bgColorOpacity={80}
          >
            <Text size="x-large" weight="bold">
              Loading...
            </Text>
          </Box>
        )}
      </div>
    </Modal>
  );
}
