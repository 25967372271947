import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "twin.macro";
import { ErrorMessage } from "../components/errors/ErrorMessage";
import {downloadClientApp} from "../services/downloads";
import {useRequestClient} from "../services/request/requestClient";
import AppContext from "../contexts/AppContext";
import PharmaciesContext from "../contexts/PharmaciesContext";

export function DownloadClientApp() {
  const requestClient = useRequestClient();
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { pharmacies, pharmaciesLoaded } = useContext(PharmaciesContext);
  const [performDownload, setPerformDownload] = useState(false);
  const [willNotDownload, setWillNotDownload] = useState(false);

  useEffect(() => {
    if (!user || !pharmaciesLoaded) {
      return;
    }

    if (user.is_admin) {
      setPerformDownload(true);
    } else {
      const pharmacy = pharmacies.find(p => p.supplierScope === "All");
      if (pharmacy) {
        setPerformDownload(true);
      } else {
        setWillNotDownload(true);
      }
    }
  }, [user, pharmacies, pharmaciesLoaded]);

  useEffect(() => {
    if (performDownload) {
      downloadClientApp(requestClient);
    }
  }, [performDownload, requestClient]);

  useEffect(() => {
    if (willNotDownload) {
      history.push("/");
    }
  }, [willNotDownload, history]);
  return (
    <>
      <main tw="pt-20 pb-[200px]">
        <ErrorMessage>
          Downloading DaylightRx App...
        </ErrorMessage>
      </main>
    </>
  );
}
