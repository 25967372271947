import type { DrugInfo } from "../../../utilities/types";

export function getDrugTag({
  small,
  drug,
  dawCode,
  isInventory,
  omitDawCode,
  noManufacturerPreference,
}: {
  drug: Pick<DrugInfo, "isRx" | "isBrand" | "isNarrowTherapeuticIndex">;
  small?: boolean;
  dawCode?: number | null;
  isInventory?: boolean;
  omitDawCode?: boolean;
  noManufacturerPreference?: boolean;
}) {
  if (!omitDawCode && dawCode && !isInventory) return `DAW ${dawCode}`;
  if (noManufacturerPreference) return;

  const { isRx, isBrand, isNarrowTherapeuticIndex } = drug;

  if (isNarrowTherapeuticIndex) {
    if (isBrand) {
      const result = small ? "BRAND & NTI" : "Brand & Narrow Thrp";
      return result;
    }

    const result = small ? "NARROW THRP" : "Narrow Therapeutic";
    return result;
  } else if (isBrand) {
    if (isRx) return "Brand RX";
    return "Brand OTC";
  }
}
