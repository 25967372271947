import "twin.macro";
import { AgGridReact } from "ag-grid-react";
import { COLUMN_DEFS } from "./NonAdherentRefillOutreachIncyte.constants";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { NonAdherentRefillOutreachIncyteDetailsCellRendered } from "./NonAdherentRefillOutreachIncyteDetailsCellRendered/NonAdherentRefillOutreachIncyteDetailsCellRendered";
import { GridOptions } from "ag-grid-community";
import { useMemo } from "react";
import sumBy from "lodash/sumBy";

const getRowStyle: GridOptions["getRowStyle"] = (params) => {
  if (params?.data?.pinnedRow) return { "font-weight": "bold" };
  return undefined;
};

function getPinnedRowData(data: any[]) {
  if (!data.length) return;

  const nonAdherentRefillOpportunitiesSum = sumBy(
    data,
    "Non-Adherent Refill Opportunities"
  );
  const attemptedOutreachesSum = sumBy(
    data,
    "Attempted Outreaches (of Non-Adherent Refill Opportunity)"
  );
  const unattemptedOutreachesSum = sumBy(
    data,
    "Unattempted Outreaches (of Non-Adherent Refill Opportunity)"
  );

  const dispensedRefillsSum = sumBy(data, "Dispensed Refills");

  const percentageAttemptedSum =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : attemptedOutreachesSum / nonAdherentRefillOpportunitiesSum;

  const percentageDispensedOfAttempted =
    attemptedOutreachesSum === 0
      ? 0
      : dispensedRefillsSum / attemptedOutreachesSum;
  const percentageDispensedOfOpportunity =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : dispensedRefillsSum / nonAdherentRefillOpportunitiesSum;

  const abandonedTransferredRefillsSum = sumBy(
    data,
    "Abandoned/Transferred Refills"
  );
  const inProgressRefillsSum = sumBy(data, "In-Progress Refills");
  const percentageInProgressOfOpportunity =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : inProgressRefillsSum / nonAdherentRefillOpportunitiesSum;

  return [
    {
      pivot: false,
      pinnedRow: true,
      "Non-Adherent Refill Opportunities": nonAdherentRefillOpportunitiesSum,
      "Attempted Outreaches (of Non-Adherent Refill Opportunity)":
        attemptedOutreachesSum,
      "Unattempted Outreaches (of Non-Adherent Refill Opportunity)":
        unattemptedOutreachesSum,
      "% Attempted (of Non-Adherent Refill Opportunity)":
        percentageAttemptedSum,
      "Dispensed Refills": dispensedRefillsSum,
      "% Dispensed of Attempted": percentageDispensedOfAttempted,
      "% Dispensed of Opportunities": percentageDispensedOfOpportunity,
      "% In Progress of Opportunities": percentageInProgressOfOpportunity,
      "Abandoned/Transferred Refills": abandonedTransferredRefillsSum,
      "In-Progress Refills": inProgressRefillsSum,
    },
  ];
}

export function NonAdherentRefillOutreachIncyte({ data }: { data: never[] }) {
  const pinnedRowData = useMemo(() => getPinnedRowData(data), [data]);
  const report = useReport({
    columnDefs: COLUMN_DEFS,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        detailRowHeight={400}
        detailCellRenderer={NonAdherentRefillOutreachIncyteDetailsCellRendered}
        rowGroupPanelShow="always"
        getRowStyle={getRowStyle}
        pinnedTopRowData={pinnedRowData}
      />
    </ReportWrapper>
  );
}
