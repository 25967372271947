import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import sort from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import type { Prescription } from "../../../utilities/types";
import { useFetchPrescriptions } from "../../../utilities/prescriptions/useFetchPrescriptions";
import { isItemTypePms } from "../../../utilities/prescriptions/itemType/isItemTypePms";
import {
  useShoppingCartServerState,
  useShoppingCartServerUpdater,
} from "../ShoppingCartServerContext/ShoppingCartServerContext";
import { useShoppingUpdater } from "../ShoppingContext/ShoppingContext";
import { useBuyingPharmacy } from "../../BuyingPharmacyContext";
import { useStorageUpdater } from "../StorageContext";
import { useServerUpdateNotifications } from "../ServerUpdateNotificationsContext";
import { mergePrescriptionsAndCartData } from "../mergePrescriptionsAndCartData";
import { getRxNumbersInCartToRemove } from "./getRxNumbersInCartToRemove";

export type SyncPrescriptionsFn = ReturnType<
  typeof useSyncedShoppingCart
>["syncPrescriptions"];

export function useSyncedShoppingCart() {
  const location = useLocation();
  const { getAllPrescriptions, getPrescriptionsByRxNumber } =
    useFetchPrescriptions();
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { cart } = useShoppingCartServerState();
  const { pushBlob, setUseBlob, loadCartState, setInitialShoppingPath } =
    useShoppingCartServerUpdater();
  const { activePrescriptionsPulledEvent } = useServerUpdateNotifications();
  const { removePrescription, setPrescriptionItemsInCart } =
    useShoppingUpdater();
  const { setWaitButtonMode, addInterval, resetInterval } = useStorageUpdater();

  const [isInventoryLoading, setIsInventoryLoading] = useState(true);
  const [inventorySyncDate, setInventorySyncDate] = useState<Dayjs>();
  const [isFetchingPrescriptions, setIsFetchingPrescriptions] = useState(true);
  const [prescriptionsSyncDate, setPrescriptionsSyncDate] = useState<Dayjs>();
  const [prescriptions, setPrescriptions] = useState<Prescription[]>();
  const [rxNumbersToSync, setRxNumbersToSync] = useState<string[]>();

  const isShoppingStateInitiated = !!prescriptionsSyncDate;
  const isPrescriptionsLoading = isFetchingPrescriptions || isInventoryLoading;

  const syncInventory = useCallback(async () => {
    if (!pharmacyId) return;

    setIsInventoryLoading(true);
    await loadCartState();
    setIsInventoryLoading(false);
    setInventorySyncDate(dayjs());
  }, [loadCartState]);

  const syncPrescriptions = useCallback(
    async (useCache = false) => {
      if (!pharmacyId || !rxNumbersToSync) {
        setPrescriptions(undefined);
        return;
      }

      setIsFetchingPrescriptions(true);
      setWaitButtonMode(true);

      const rxNumbers = cart
        .filter((item) => !isItemTypePms(item))
        .map((item) => item.id);
      const newPrescriptions = await getAllPrescriptions({
        useCache,
        rxNumbers,
        pharmacyId,
      });

      setPrescriptions((prevState) => {
        if (isEqual(prevState, newPrescriptions)) return prevState;
        return newPrescriptions;
      });

      setPrescriptionsSyncDate(dayjs());
      setWaitButtonMode(false);
      setIsFetchingPrescriptions(false);
    },
    [
      pharmacyId,
      rxNumbersToSync,
      setWaitButtonMode,
      getAllPrescriptions,
      getPrescriptionsByRxNumber,
    ]
  );

  useEffect(() => {
    const newRxNumbersToSync = sort(cart.map((item) => item.id));
    setRxNumbersToSync((prevState) => {
      if (isEqual(prevState, newRxNumbersToSync)) return prevState;
      return newRxNumbersToSync;
    });
  }, [cart]);

  useEffect(() => {
    if (
      !prescriptions ||
      isInventoryLoading ||
      isPrescriptionsLoading ||
      !isShoppingStateInitiated
    ) {
      return;
    }

    const rxNumbersInCartToRemove = getRxNumbersInCartToRemove({
      cart,
      prescriptions,
    });
    if (rxNumbersInCartToRemove?.length) {
      removePrescription(rxNumbersInCartToRemove);
      return;
    }

    const newItemsInCart = mergePrescriptionsAndCartData(prescriptions, cart);
    setPrescriptionItemsInCart((prevState) => {
      if (isEqual(prevState, newItemsInCart)) return prevState;
      return newItemsInCart;
    });
  }, [
    cart,
    prescriptions,
    isInventoryLoading,
    isPrescriptionsLoading,
    isShoppingStateInitiated,
    removePrescription,
    setPrescriptionItemsInCart,
  ]);

  useEffect(() => {
    if (!isShoppingStateInitiated) return;

    // start pushing
    const blobInterval = setInterval(() => {
      pushBlob({ second: false, force: false });
    }, 2000);
    addInterval({ id: pharmacyId, intervalId: blobInterval });

    return () => clearInterval(blobInterval);
  }, [isShoppingStateInitiated]);

  useEffect(() => {
    if (activePrescriptionsPulledEvent > 0) syncPrescriptions(true);
  }, [activePrescriptionsPulledEvent, syncPrescriptions]);

  useEffect(() => {
    setInitialShoppingPath(location.pathname);
    setPrescriptions(undefined);
    setInventorySyncDate(undefined);
    setPrescriptionsSyncDate(undefined);
    setPrescriptionItemsInCart([]);
    resetInterval();
    if (!pharmacyId) return;

    setUseBlob(true);
    syncInventory();
    syncPrescriptions();
  }, [pharmacyId]);

  return {
    inventorySyncDate,
    isInventoryLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    syncInventory,
    syncPrescriptions,
  };
}
