import React from "react";
import "../../../scss/base.scss";
import "../../../scss/shoppingTables.scss";
import "../../../scss/buttonSlot.scss";
import "../../../scss/loader.scss";
import { WideContainer } from "../../../components/containers/WideContainer";
import { NavBar } from "../../../components/NavBar/NavBar";
import { Box } from "../../../components/rxLibrary/box";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { useSyncedShoppingCart } from "../../../contexts/shoppingContexts/useSyncedShoppingCart/useSyncedShoppingCart";
import { ShoppingListBar } from "./ShoppingListBar/ShoppingListBar";
import { useShoppingListItems } from "./useShoppingListItems/useShoppingListItems";
import { ShoppingListTableInventory } from "./ShoppingListTableInventory/ShoppingListTableInventory";
import { ShoppingListTablePms } from "./ShoppingListTablePms/ShoppingListTablePms";
import { useSearchBarItems } from "./searchBar";

export function ShoppingList() {
  const {
    isInventoryLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    syncPrescriptions,
  } = useSyncedShoppingCart();

  const {
    cartItemsSort,
    cartItemsFilters,
    cartItemsSortOrder,
    hasCartItemsFilters,
    pmsWindowScrollerRef,
    hideOptimizedPmsItems,
    hideOptimizedInventoryItems,
    inventoryItemsWithVisibility,
    prescriptionItemsWithVisibility,
    setCartItemsSort,
    setSearchBarFilter,
    setCartItemsFilters,
    setCartItemsSortOrder,
    setHideOptimizedPmsItems,
    setHideOptimizedInventoryItems,
  } = useShoppingListItems();

  const searchBarItems = useSearchBarItems(
    inventoryItemsWithVisibility,
    prescriptionItemsWithVisibility
  );

  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const { isSynced } = pharmacy ?? {};

  return (
    <Box tw="min-h-screen" bgColor="grey-4">
      <NavBar />

      <ShoppingListBar
        searchBarItems={searchBarItems}
        cartItemsSort={cartItemsSort}
        cartItemsFilters={cartItemsFilters}
        cartItemsSortOrder={cartItemsSortOrder}
        setCartItemsSort={setCartItemsSort}
        setSearchBarFilter={setSearchBarFilter}
        setCartItemsFilters={setCartItemsFilters}
        setCartItemsSortOrder={setCartItemsSortOrder}
      />

      <WideContainer tw="relative pt-[48px] pb-[119px]">
        <ShoppingListTableInventory
          loading={isInventoryLoading}
          hasCartItemsFilters={hasCartItemsFilters}
          hideOptimizedInventoryItems={hideOptimizedInventoryItems}
          inventoryItemsWithVisibility={inventoryItemsWithVisibility}
          setHideOptimizedInventoryItems={setHideOptimizedInventoryItems}
        />

        {!!isSynced && (
          <>
            <div tw="mb-[48px]" />

            <ShoppingListTablePms
              loading={isPrescriptionsLoading}
              prescriptionsSyncDate={prescriptionsSyncDate}
              hasCartItemsFilters={hasCartItemsFilters}
              pmsWindowScrollerRef={pmsWindowScrollerRef}
              hideOptimizedPmsItems={hideOptimizedPmsItems}
              prescriptionItemsWithVisibility={prescriptionItemsWithVisibility}
              syncPrescriptions={syncPrescriptions}
              setHideOptimizedPmsItems={setHideOptimizedPmsItems}
            />
          </>
        )}
      </WideContainer>
    </Box>
  );
}
