"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkSession = checkSession;
exports.logIn = logIn;
exports.skipQuickAuth = skipQuickAuth;
var _quick_auth = require("../quick_auth");
var _index = require("../store/index");
var _actions = require("../core/actions");
var l = _interopRequireWildcard(require("../core/index"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function skipQuickAuth(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _quick_auth.skipQuickAuth, true);
}
function logIn(id, connection, loginHint, prompt) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var connectionScopes = l.auth.connectionScopes(m);
  var scopes = connectionScopes.get(connection.get('name'));
  var params = {
    connection: connection.get('name'),
    connection_scope: scopes ? scopes.toJS() : undefined
  };
  if (!l.auth.redirect(m) && connection.get('strategy') === 'facebook') {
    params.display = 'popup';
  }
  if (loginHint) {
    params.login_hint = loginHint;
  }
  if (prompt) {
    params.prompt = prompt;
  }
  if (connection.get('strategy') === 'apple') {
    (0, _index.swap)(_index.updateEntity, 'lock', l.id(m), l.setSupressSubmitOverlay, true);
  } else {
    (0, _index.swap)(_index.updateEntity, 'lock', l.id(m), l.setSupressSubmitOverlay, false);
  }
  params.isSubmitting = false;
  (0, _actions.logIn)(id, [], params);
}
function checkSession(id, connection, loginHint) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  if (l.auth.responseType(m).indexOf('code') >= 0) {
    // we need to force a redirect in this case
    // so we use login with prompt=none
    return logIn(id, connection, loginHint, 'none');
  } else {
    var connectionScopes = l.auth.connectionScopes(m);
    var scopes = connectionScopes.get(connection.get('name'));
    var params = _objectSpread(_objectSpread({}, l.auth.params(m).toJS()), {}, {
      connection: connection.get('name')
    });
    (0, _actions.checkSession)(id, params);
  }
}
