import React, { RefObject, memo, useMemo } from "react";
import "twin.macro";
import WindowScroller from "react-virtualized/dist/commonjs/WindowScroller";
import type { Dayjs } from "dayjs";
import type { SyncPrescriptionsFn } from "../../../../contexts/shoppingContexts/useSyncedShoppingCart/useSyncedShoppingCart";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { Col, Row } from "../../../../components/rxLibrary/grid";
import { Text } from "../../../../components/rxLibrary/typography";
import { ShoppingListColumnPackageInfo } from "../../../../components/shopping/table/columns/ShoppingListColumnPackageInfo";
import { ShoppingListColumnDispensedItem } from "../shoppingListTable/columns/ShoppingListColumnDispensedItem";
import { ShoppingListTableHeaderItem } from "../shoppingListTable/ShoppingListTableHeaderItem";
import { ShoppingListTableWrapper } from "../shoppingListTable/ShoppingListTableWrapper";
import { ShoppingListColumnActions } from "../shoppingListTable/columns/ShoppingListColumnActions/ShoppingListColumnActions";
import {
  SHOPPING_LIST_TABLE_NAV_BAR_STICKY_HEIGHT,
  ShoppingListTableNavBarSticky,
} from "../shoppingListTable/ShoppingListTableNavBarSticky";
import { ShoppingListColumnDispensedQuantity } from "../shoppingListTable/columns/ShoppingListColumnDispensedQuantity";
import { ShoppingListColumnPrescriber } from "../shoppingListTable/columns/ShoppingListColumnPrescriber";
import { ShoppingListTableOptimizedSwitchFilter } from "../shoppingListTable/ShoppingListTableOptimizedSwitchFilter";
import { ShoppingListTableHeaderSticky } from "../shoppingListTable/ShoppingListTableHeaderSticky";
import { ShoppingListCol } from "../shoppingListTable/columns/ShoppingListCol";
import { ItemInCartWithVisibility } from "../useShoppingListItems/useShoppingListItems.constants";
import { ShoppingListRow } from "../shoppingListTable/ShoppingListRow";
import { useDuplicatedDrugFamilyPopover } from "../shoppingListTable/duplicatedDrugFamily/useDuplicatedDrugFamilyPopover";
import { SubstitutionsCol } from "../shoppingListTable/columns/SubstitutionsCol/SubstitutionsCol";
import { ShoppingListTablePmsVirtualizedList } from "./ShoppingListTablePmsVirtualizedList";
import { ShoppingListTablePmsHeader } from "./ShoppingListTablePmsHeader";

const OFFSET_TOP_EMPTY_CART = 168;
const OFFSET_TOP_WITH_CART_ITEMS = 212;

function ShoppingListTablePmsComponent({
  loading,
  hasCartItemsFilters,
  pmsWindowScrollerRef,
  prescriptionsSyncDate,
  hideOptimizedPmsItems,
  prescriptionItemsWithVisibility,
  syncPrescriptions,
  setHideOptimizedPmsItems,
}: {
  loading: boolean;
  hasCartItemsFilters: boolean;
  pmsWindowScrollerRef: RefObject<WindowScroller>;
  hideOptimizedPmsItems: boolean;
  prescriptionItemsWithVisibility: ItemInCartWithVisibility[];
  prescriptionsSyncDate?: Dayjs;
  syncPrescriptions: SyncPrescriptionsFn;
  setHideOptimizedPmsItems: (isHide: boolean) => void;
}) {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const { pms } = pharmacy ?? {};

  const offsetTopNavBar = hasCartItemsFilters
    ? OFFSET_TOP_WITH_CART_ITEMS
    : OFFSET_TOP_EMPTY_CART;
  const prescriptionItemsInCartCount = prescriptionItemsWithVisibility.length;

  const offsetTopTableHeader = useMemo(() => {
    return offsetTopNavBar + SHOPPING_LIST_TABLE_NAV_BAR_STICKY_HEIGHT;
  }, [offsetTopNavBar]);

  const visiblePrescriptionItems = useMemo(() => {
    return prescriptionItemsWithVisibility.filter((item) => item.visible);
  }, [prescriptionItemsWithVisibility]);

  const visiblePrescriptionItemsCount = visiblePrescriptionItems.length;

  const emptyText = useMemo(() => {
    return `No ${pms ?? "PMS"} items to fetch.`;
  }, [pms]);

  const {
    renderDuplicatedDrugFamilyPopover,
    updatePrescriptionStatusWithDrugFamilyValidation,
  } = useDuplicatedDrugFamilyPopover({
    excludePmsPrescriptions: true,
  });

  return (
    <>
      <ShoppingListTableNavBarSticky
        offsetTop={offsetTopNavBar}
        stickyBorderTop
      >
        <ShoppingListTableHeaderItem>
          <ShoppingListTablePmsHeader
            loading={loading}
            prescriptionsSyncDate={prescriptionsSyncDate}
            prescriptionItemsInCartCount={prescriptionItemsInCartCount}
            visiblePrescriptionItemsCount={visiblePrescriptionItems.length}
            syncPrescriptions={syncPrescriptions}
          />
        </ShoppingListTableHeaderItem>

        <ShoppingListTableHeaderItem>
          {!loading && (
            <ShoppingListTableOptimizedSwitchFilter
              items={prescriptionItemsWithVisibility}
              hideOptimizedItems={hideOptimizedPmsItems}
              setHideOptimizedItems={setHideOptimizedPmsItems}
            />
          )}
        </ShoppingListTableHeaderItem>
      </ShoppingListTableNavBarSticky>

      <ShoppingListTableWrapper
        loading={loading}
        itemsCount={prescriptionItemsInCartCount}
        visibleItemsCount={visiblePrescriptionItemsCount}
        emptyText={emptyText}
      >
        <ShoppingListTableHeaderSticky offsetTop={offsetTopTableHeader}>
          <Row header>
            <Col tw="w-[60px]">
              <Text weight="bold">Rx #</Text>
            </Col>

            <Col tw="w-[63px]">
              <Text weight="bold">Patient</Text>
            </Col>

            <Col tw="w-[154px]">
              <Text weight="bold">Date Filled / Prescriber</Text>
            </Col>

            <Col tw="flex-1">
              <Text weight="bold">Dispensed Item</Text>
            </Col>

            <Col tw="w-[133px]">
              <Text weight="bold">Pack / Manufacturer</Text>
            </Col>

            <Col tw="w-[147px]">
              <Text weight="bold">Dispensed Quantity</Text>
            </Col>

            <Col tw="w-[133px]">
              <Text weight="bold">Allow Substitutions</Text>
            </Col>

            <Col tw="w-[167px]" />
          </Row>
        </ShoppingListTableHeaderSticky>

        <ShoppingListTablePmsVirtualizedList
          rowCount={visiblePrescriptionItems.length}
          windowScrollerRef={pmsWindowScrollerRef}
        >
          {({ index }) => {
            const visiblePrescriptionItem = visiblePrescriptionItems[index];
            const { prescription, filtered, visible } = visiblePrescriptionItem;
            const id = getPrescriptionId(prescription);

            return (
              <ShoppingListRow
                key={id}
                visible={visible}
                filtered={filtered}
                prescriptionId={id}
                renderWrapper={renderDuplicatedDrugFamilyPopover}
              >
                <ShoppingListCol tw="w-[60px]">
                  <Text weight="bold">{prescription.rxNumber}</Text>
                </ShoppingListCol>

                <ShoppingListCol tw="w-[63px]">
                  <Text weight="bold">{prescription?.patient?.shortName}</Text>
                </ShoppingListCol>

                <ShoppingListCol tw="w-[154px]">
                  <ShoppingListColumnPrescriber
                    prescriber={prescription.prescriber}
                    fillDate={prescription.fillDate}
                  />
                </ShoppingListCol>

                <ShoppingListCol tw="flex-1">
                  <ShoppingListColumnDispensedItem
                    drug={prescription.drug}
                    displayNdc
                  />
                </ShoppingListCol>

                <ShoppingListCol tw="w-[133px]">
                  <ShoppingListColumnPackageInfo drug={prescription.drug} />
                </ShoppingListCol>

                <ShoppingListCol tw="w-[147px]">
                  <ShoppingListColumnDispensedQuantity
                    prescription={prescription}
                  />
                </ShoppingListCol>

                <ShoppingListCol tw="w-[133px]">
                  <SubstitutionsCol prescription={prescription} />
                </ShoppingListCol>

                <ShoppingListCol tw="w-[167px]">
                  <ShoppingListColumnActions
                    prescription={prescription}
                    updatePrescriptionStatus={
                      updatePrescriptionStatusWithDrugFamilyValidation
                    }
                  />
                </ShoppingListCol>
              </ShoppingListRow>
            );
          }}
        </ShoppingListTablePmsVirtualizedList>
      </ShoppingListTableWrapper>
    </>
  );
}

export const ShoppingListTablePms = memo(ShoppingListTablePmsComponent);
