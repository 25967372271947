import React, { useCallback, useMemo } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import type { ItemInCart } from "../../../../utilities/types";
import { normalizePackQuantity } from "../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { formatPackQuantity } from "../../../../utilities/prescriptions/packQuantity/formatPackQuantity";
import { useShoppingUpdater } from "../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { Text } from "../../../../components/rxLibrary/typography";
import { formatBundleSizeDropdownItems } from "../../../../utilities/prescriptions/formatDirectDealDropdownItems.deprecated";
import { ManufacturerDirectDeal } from "../../../../components/shopping/ManufacturerDirectDeal";
import { getDirectDeal } from "../../../../utilities/prescriptions/getDirectDeal";

const SELECT_MUI_STYLES = {
  "& .MuiSelect-select": { padding: 0, lineHeight: 1 },
  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
};

export function PackQuantityDropdown({
  prescription,
}: {
  prescription: ItemInCart;
}) {
  const directDeal = getDirectDeal(prescription);

  const { updatePrescriptionPackQuantity } = useShoppingUpdater();

  const packQuantity = useMemo(() => {
    return normalizePackQuantity(prescription.packQuantity);
  }, [prescription.packQuantity]);

  const selectValue = useMemo(() => {
    return packQuantity.toString();
  }, [packQuantity]);

  const label = useMemo(() => {
    return formatPackQuantity(prescription);
  }, [prescription]);

  const dropdownItems = useMemo(() => {
    return formatBundleSizeDropdownItems({
      bundleSize: directDeal?.bundleSize,
      directDealLabel: true,
      includeZero: true,
      zeroLabel: "Delete",
    });
  }, [directDeal]);

  const updatePackQuantity = useCallback(
    (event: { target: { value: string } }) => {
      const packQuantity = parseInt(event.target.value);
      updatePrescriptionPackQuantity(prescription, packQuantity);
    },
    [prescription, updatePrescriptionPackQuantity]
  );

  return (
    <>
      <Select
        sx={SELECT_MUI_STYLES}
        IconComponent={() => null}
        value={selectValue}
        onChange={updatePackQuantity}
        renderValue={() => <Text tw="underline">{label}</Text>}
      >
        {dropdownItems.map(({ value, label }) => (
          <MenuItem key={value} value={value} disableRipple>
            <Text>{label}</Text>
          </MenuItem>
        ))}
      </Select>

      <ManufacturerDirectDeal
        directDeal={directDeal}
        packQuantity={packQuantity}
      />
    </>
  );
}
