import { useCallback, useMemo } from "react";
import { Tooltip } from "antd";
import tw from "twin.macro";
import type { ItemInCart } from "../../../utilities/types";
import { formatDrugNameWithDetails } from "../../../utilities/drugInfo/formatDrugNameWithDetails";
import { formatDispenseQuantity } from "../../../utilities/prescriptions/formatDispenseQuantity";
import { getPrescriptionId } from "../../../utilities/prescriptions/getPrescriptionId";
import { isInventoryId } from "../../../utilities/prescriptions/isInventoryId";
import { WideContainer } from "../../../components/containers/WideContainer";
import { Box } from "../../../components/rxLibrary/box";
import { Text } from "../../../components/rxLibrary/typography";
import { IconBell, IconX } from "../../../components/rxLibrary/icons";
import { Button, IconButton } from "../../../components/rxLibrary/buttons";
import { useShoppingRx } from "./useShoppingRx/useShoppingRx";
import { TEXT_ELLIPSIS_TW } from "../../../components/rxLibrary/typography/Text";

function Item({ item }: { item: ItemInCart }) {
  const { selectItemId: selectItemId } = useShoppingRx();

  const id = useMemo(() => getPrescriptionId(item), [item]);
  const isInventory = useMemo(() => isInventoryId(id), [id]);
  const tag = isInventory ? "Inventory" : "Prescription";

  const text = useMemo(() => {
    const drugName = formatDrugNameWithDetails(item.drug);
    const dispensedQuantity = formatDispenseQuantity(item);
    return (
      (isInventory ? "" : `Rx# ${id} - `) + `${drugName}. ${dispensedQuantity}`
    );
  }, [id, item]);

  const handleClick = useCallback(() => {
    selectItemId(id);
  }, [id, selectItemId]);

  return (
    <div key={text} tw="flex items-center space-x-[8px]">
      <Box
        tw="flex items-center h-[20px] px-[6px] rounded-[3px]"
        bgColor={isInventory ? "yellow-2" : "blue-4"}
      >
        <Text size="medium">{tag}</Text>
      </Box>

      <Button variant="text-3" onClick={handleClick}>
        <Tooltip title={text}>
          <span tw="block max-w-[367px]" css={TEXT_ELLIPSIS_TW}>
            {text}
          </span>
        </Tooltip>
      </Button>
    </div>
  );
}

export function ShoppingRxSimilarItems() {
  const { similarItems, removeSimilarItem } = useShoppingRx();

  if (!similarItems) return null;
  return (
    <WideContainer tw="relative mb-[10px]">
      <Box
        tw="flex border p-[2px] rounded-[3px]"
        bgColor="white-1"
        borderColor="red-1"
      >
        <Box tw="w-[10px] rounded-[3px]" bgColor="red-1" />

        <div tw="flex-1 overflow-y-auto max-h-[114px] pl-[10px] pr-[20px]">
          {similarItems.map((item, i) => {
            const { ndc, prescriptions, inventory } = item;
            const hasBorder = i !== similarItems.length - 1;

            function remove() {
              removeSimilarItem(ndc);
            }

            return (
              <Box
                key={ndc}
                tw="flex flex-1 items-center py-[8px] first:py-[4px]"
                css={hasBorder && tw`border-b pt-[8px] pb-[7px]`}
                borderColor="grey-2"
              >
                <div tw="flex items-center space-x-[6px] w-[60px]">
                  <IconBell color="red-1" />
                  <Text size="medium" weight="bold" block>
                    Note:
                  </Text>
                </div>

                <Box tw="flex items-center flex-1 space-y-[8px]">
                  {prescriptions.map((item) => (
                    <Item key={getPrescriptionId(item)} item={item} />
                  ))}
                </Box>

                <Text tw="px-[28px]" center block>
                  is similar to
                </Text>

                <Box tw="flex items-center flex-1 space-y-[8px]">
                  {inventory.map((item) => (
                    <Item key={getPrescriptionId(item)} item={item} />
                  ))}
                </Box>

                <div tw="flex items-center justify-center w-[60px]">
                  <IconButton onClick={remove}>
                    <IconX color="grey-2" />
                  </IconButton>
                </div>
              </Box>
            );
          })}
        </div>
      </Box>
    </WideContainer>
  );
}
