import { useMemo } from "react";
import type { ItemInCart, ItemInModal } from "../../../utilities/types";
import { Tag } from "../../rxLibrary/tags";
import { geDiscountPercentage } from "./geDiscountPercentage";
import { getDrugTag } from "./getDrugTag";

export function PrescriptionTags({
  item,
  small,
  omitDawCode,
  isInventory,
  packQuantity,
}: {
  item: ItemInCart | ItemInModal;
  small?: boolean;
  omitDawCode?: boolean;
  isInventory?: boolean;
  packQuantity?: number;
}) {
  const { drug, dawCode, directDeals, noManufacturerPreference } = item;

  const drugTag = useMemo(() => {
    return getDrugTag({
      drug,
      small,
      dawCode,
      isInventory,
      omitDawCode,
      noManufacturerPreference,
    });
  }, [
    small,
    drug,
    dawCode,
    omitDawCode,
    isInventory,
    noManufacturerPreference,
  ]);

  const discountPercent = useMemo(() => {
    return geDiscountPercentage({
      directDeals,
      packQuantity,
      noManufacturerPreference,
    });
  }, [directDeals, packQuantity, noManufacturerPreference]);

  if (!drugTag && !discountPercent) return null;

  return (
    <div tw="flex items-center space-x-[2px]">
      {!!drugTag && <Tag>{drugTag}</Tag>}
      {!!discountPercent && (
        <Tag>
          <img
            src="/assets/shopping/dollar-arrow-down.png"
            alt="$"
            tw="h-[14px] mr-[2px]"
          />
          <span>WAC-{discountPercent}%</span>
        </Tag>
      )}
    </div>
  );
}
