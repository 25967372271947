"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelHRD = cancelHRD;
exports.logIn = logIn;
exports.startHRD = startHRD;
var _index = require("../../store/index");
var _enterprise = require("../enterprise");
var _index2 = require("../../field/index");
var _email = require("../../field/email");
var _actions = require("../../core/actions");
var l = _interopRequireWildcard(require("../../core/index"));
var _captcha = require("../captcha");
var _index4 = require("../database/index");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // TODO: enterprise connections should not depend on database
// connections. However, we now allow a username input to contain also
// an email and this information is in the database module. We should
// make this information flow from the UI (like we do for the startHRD
// function). Including this dependency here allows us to do that
// incrementally.
function startHRD(id, email) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _enterprise.toggleHRD, email);
}
function cancelHRD(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = (0, _enterprise.toggleHRD)(m, false);
    m = (0, _index2.hideInvalidFields)(m);
    return m;
  });
}
function getConnectionScopesFrom(m, connection) {
  var connectionScopes = l.auth.connectionScopes(m);
  return connectionScopes.get(connection.get('name'));
}
function logIn(id) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var email = (0, _index2.getFieldValue)(m, (0, _index4.databaseLogInWithEmail)(m) ? 'email' : 'username');
  var ssoConnection = (0, _enterprise.matchConnection)(m, email);
  var enterpriseConnection = (0, _enterprise.enterpriseActiveFlowConnection)(m);
  var connectionScopes = getConnectionScopesFrom(m, ssoConnection || enterpriseConnection);
  var usernameField = (0, _index4.databaseLogInWithEmail)(m) ? 'email' : 'username';
  var fields = [usernameField, 'password'];
  var params = {
    connection_scope: connectionScopes ? connectionScopes.toJS() : undefined
  };
  if (ssoConnection && !(0, _enterprise.isHRDActive)(m)) {
    return logInSSO(id, ssoConnection, params);
  }
  var isCaptchaValid = (0, _captcha.setCaptchaParams)(m, params, _captcha.Flow.DEFAULT, fields);
  if (!isCaptchaValid && !ssoConnection) {
    return (0, _captcha.showMissingCaptcha)(m, id);
  }
  logInActiveFlow(id, params);
}
function logInActiveFlow(id, params) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var usernameField = (0, _enterprise.isHRDActive)(m) || !(0, _index4.databaseLogInWithEmail)(m) ? 'username' : 'email';
  var originalUsername = (0, _index2.getFieldValue)(m, usernameField);
  var connection = (0, _enterprise.enterpriseActiveFlowConnection)(m);
  var username = l.defaultADUsernameFromEmailPrefix(m) ? (0, _email.emailLocalPart)(originalUsername) : originalUsername;
  (0, _actions.logIn)(id, ['password', usernameField], _objectSpread(_objectSpread({}, params), {}, {
    connection: connection ? connection.get('name') : null,
    username: username,
    password: (0, _index2.getFieldValue)(m, 'password'),
    login_hint: username
  }), function (id, error, fields, next) {
    var wasCaptchaInvalid = error && error.code === 'invalid captcha';
    (0, _captcha.swapCaptcha)(id, _captcha.Flow.DEFAULT, wasCaptchaInvalid, next);
  });
}
function logInSSO(id, connection, params) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var field = (0, _index4.databaseLogInWithEmail)(m) ? 'email' : 'username';
  l.emitEvent(m, 'sso login', {
    lockID: id,
    connection: connection,
    field: field
  });
  (0, _actions.logIn)(id, [field], _objectSpread(_objectSpread({}, params), {}, {
    connection: connection.get('name'),
    login_hint: (0, _index2.getFieldValue)(m, field)
  }));
}
