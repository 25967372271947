"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkSession = checkSession;
exports.closeLock = closeLock;
exports.handleAuthCallback = handleAuthCallback;
exports.logIn = logIn;
exports.logInSuccess = logInSuccess;
exports.openLock = openLock;
exports.pinLoadingPane = pinLoadingPane;
exports.removeLock = removeLock;
exports.resumeAuth = resumeAuth;
exports.setupLock = setupLock;
exports.unpinLoadingPane = unpinLoadingPane;
exports.updateLock = updateLock;
exports.validateAndSubmit = validateAndSubmit;
var _immutable = _interopRequireWildcard(require("immutable"));
var _web_api = _interopRequireDefault(require("./web_api"));
var _index = require("../store/index");
var _remote_data = require("./remote_data");
var l = _interopRequireWildcard(require("./index"));
var _preload_utils = require("../utils/preload_utils");
var _container = require("../ui/box/container");
var _index3 = require("../field/index");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function setupLock(id, clientID, domain, options, hookRunner, emitEventFn, handleEventFn) {
  var m = l.setup(id, clientID, domain, options, hookRunner, emitEventFn, handleEventFn);
  m = (0, _remote_data.syncRemoteData)(m);
  (0, _preload_utils.img)(l.ui.logo(m) || _container.defaultProps.logo);
  _web_api.default.setupClient(id, clientID, domain, l.withAuthOptions(m, _objectSpread(_objectSpread({}, options), {}, {
    popupOptions: l.ui.popupOptions(m)
  })));
  m = l.runHook(m, 'didInitialize', options);
  (0, _index.swap)(_index.setEntity, 'lock', id, m);
  return m;
}
function handleAuthCallback() {
  var ms = (0, _index.read)(_index.getCollection, 'lock');
  var keepHash = ms.filter(function (m) {
    return !l.hashCleanup(m);
  }).size > 0;
  var urlWithoutHash = window.location.href.split('#')[0];
  var callback = function callback(error, authResult) {
    var parsed = !!(error || authResult);
    if (parsed && !keepHash) {
      window.history.replaceState(null, '', urlWithoutHash);
    }
  };
  resumeAuth(window.location.hash, callback);
}
function resumeAuth(hash, callback) {
  var ms = (0, _index.read)(_index.getCollection, 'lock');
  ms.forEach(function (m) {
    return l.auth.redirect(m) && parseHash(m, hash, callback);
  });
}
function parseHash(m, hash, cb) {
  _web_api.default.parseHash(l.id(m), hash, function (error, authResult) {
    if (error) {
      l.emitHashParsedEvent(m, error);
    } else {
      l.emitHashParsedEvent(m, authResult);
    }
    if (error) {
      l.emitAuthorizationErrorEvent(m, error);
    } else if (authResult) {
      l.emitAuthenticatedEvent(m, authResult);
    }
    cb(error, authResult);
  });
}
function openLock(id, opts) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  if (!m) {
    throw new Error("The Lock can't be opened again after it has been destroyed");
  }
  if (l.rendering(m)) {
    return false;
  }
  if (opts.flashMessage) {
    var supportedTypes = ['error', 'success', 'info'];
    if (!opts.flashMessage.type || supportedTypes.indexOf(opts.flashMessage.type) === -1) {
      return l.emitUnrecoverableErrorEvent(m, "'flashMessage' must provide a valid type ['error','success','info']");
    }
    if (!opts.flashMessage.text) {
      return l.emitUnrecoverableErrorEvent(m, "'flashMessage' must provide a text");
    }
  }
  l.emitEvent(m, 'show');
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = l.overrideOptions(m, opts);
    m = l.filterConnections(m);
    m = l.runHook(m, 'willShow', opts);
    return l.render(m);
  });
  return true;
}
function closeLock(id) {
  var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};
  // Do nothing when the Lock can't be closed, unless closing is forced.
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  if (!l.ui.closable(m) && !force || !l.rendering(m)) {
    return;
  }
  l.emitEvent(m, 'hide');

  // If it is a modal, stop rendering an reset after a second,
  // otherwise just reset.
  if (l.ui.appendContainer(m)) {
    (0, _index.swap)(_index.updateEntity, 'lock', id, l.stopRendering);
    setTimeout(function () {
      (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
        m = (0, _index3.hideInvalidFields)(m);
        m = l.reset(m);
        m = (0, _index3.clearFields)(m);
        return m;
      });
      m = (0, _index.read)(_index.getEntity, 'lock', id);
      callback(m);
    }, 1000);
  } else {
    (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
      m = (0, _index3.hideInvalidFields)(m);
      m = l.reset(m);
      m = (0, _index3.clearFields)(m);
      return m;
    });
    callback(m);
  }
}
function removeLock(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.stopRendering);
  (0, _index.swap)(_index.removeEntity, 'lock', id);
}
function updateLock(id, f) {
  return (0, _index.swap)(_index.updateEntity, 'lock', id, f);
}
function pinLoadingPane(id) {
  var lock = (0, _index.read)(_index.getEntity, 'lock', id);
  if (!lock.get('isLoadingPanePinned')) {
    (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
      return m.set('isLoadingPanePinned', true);
    });
  }
}
function unpinLoadingPane(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    return m.set('isLoadingPanePinned', false);
  });
}
function validateAndSubmit(id) {
  var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var f = arguments.length > 2 ? arguments[2] : undefined;
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    var allFieldsValid = fields.reduce(function (r, x) {
      return r && (0, _index3.isFieldValid)(m, x);
    }, true);
    return allFieldsValid ? l.setSubmitting(m, true) : fields.reduce(function (r, x) {
      return (0, _index3.showInvalidField)(r, x);
    }, m);
  });
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  if (l.submitting(m)) {
    f(m);
  }
}
function logIn(id, fields) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var logInErrorHandler = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function (_id, error, _fields, next) {
    return next();
  };
  validateAndSubmit(id, fields, function (m) {
    try {
      // For now, always pass 'null' for the context as we don't need it yet.
      // If we need it later, it'll save a breaking change in hooks already in use.
      var context = null;
      l.runHook(m, 'loggingIn', context, function () {
        _web_api.default.logIn(id, params, l.auth.params(m).toJS(), function (error, result) {
          if (error) {
            setTimeout(function () {
              return logInError(id, fields, error, logInErrorHandler);
            }, 250);
          } else {
            logInSuccess(id, result);
          }
        });
      });
    } catch (e) {
      setTimeout(function () {
        return logInError(id, fields, e, logInErrorHandler);
      }, 250);
    }
  });
}
function checkSession(id) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    return l.setSubmitting(m, true);
  });
  _web_api.default.checkSession(id, params, function (err, result) {
    if (err) {
      return logInError(id, [], err);
    }
    return logInSuccess(id, result);
  });
}
function logInSuccess(id, result) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  if (!l.ui.autoclose(m)) {
    (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
      m = l.setSubmitting(m, false);
      return l.setLoggedIn(m, true);
    });
    l.emitAuthenticatedEvent(m, result);
  } else {
    closeLock(id, false, function (m1) {
      return l.emitAuthenticatedEvent(m1, result);
    });
  }
}
function logInError(id, fields, error) {
  var localHandler = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function (_id, _error, _fields, next) {
    return next();
  };
  var errorCode = error.error || error.code;
  localHandler(id, error, fields, function () {
    return setTimeout(function () {
      var m = (0, _index.read)(_index.getEntity, 'lock', id);
      var errorMessage = l.loginErrorMessage(m, error, loginType(fields));
      var errorCodesThatEmitAuthorizationErrorEvent = ['blocked_user', 'rule_error', 'lock.unauthorized', 'invalid_user_password', 'login_required'];
      if (errorCodesThatEmitAuthorizationErrorEvent.indexOf(errorCode) > -1) {
        l.emitAuthorizationErrorEvent(m, error);
      }
      (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
    }, 0);
  });
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false);
}
function loginType(fields) {
  if (!fields) return;
  if (~fields.indexOf('vcode')) return 'code';
  if (~fields.indexOf('username')) return 'username';
  if (~fields.indexOf('email')) return 'email';
}
