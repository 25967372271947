import React, { createContext, useEffect, useState, ReactNode } from "react";
import { deepEqual } from "fast-equals";
import sortBy from "lodash/sortBy";
import { getPharmacies } from "../services/pharmacies";
import { Pharmacy } from "../utilities/types";
import { authService } from "../libs/Authentication";

type PharmaciesContext = {
  pharmacies: Pharmacy[];
  pharmaciesLoaded: boolean;
  supplierFullNameById: {[key: string]: string},
  refreshPharmacies: () => Promise<Pharmacy[] | undefined>;
};

const PharmaciesContext = createContext<PharmaciesContext>({
  pharmacies: [],
  pharmaciesLoaded: false,
  supplierFullNameById: {},
  refreshPharmacies: async () => { return undefined;},
});

export default PharmaciesContext;

function formatPharmacies(newPharmacies: Pharmacy[]) {
  const formattedPharmacies = newPharmacies
    .map((pharmacy): Pharmacy => {
      const { reports, suppliers } = pharmacy;
      const sortSuppliers = [...suppliers].sort((s1, s2) => {
        return s1.displayName.localeCompare(s2.displayName);
      });
      const sortedReports = sortBy(reports, ["displaySequenceNumber", "title"]);

      return { ...pharmacy, suppliers: sortSuppliers, reports: sortedReports };
    })
    .sort((p1, p2) => {
      return p1.name.localeCompare(p2.name);
    });

  return formattedPharmacies;
}

export function PharmaciesContextProvider({ children }: { children?: ReactNode }) {
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [pharmaciesLoaded, setPharmaciesLoaded] = useState(false);
  const [supplierFullNameById, setSupplierFullNameById] = useState<{[key: string]: string}>({});

  useEffect(() => {
    (async () => {
      const token = await authService.getAccessTokenSilently();
      const { data } = await getPharmacies(token);
      console.log("getPharmacies", data);
      if (!data?.pharmacies) return;

      const newPharmacies = formatPharmacies(data.pharmacies);
      setPharmacies(newPharmacies);
      setPharmaciesLoaded(true);
    })();
  }, []);

  useEffect(() => {
    const lookup: {[key: string]: string}  = {};
    pharmacies.forEach((pharm)=> {
      pharm.suppliers.forEach((supplier) => {
        if (!(supplier.id in lookup)) {
          lookup[supplier.id] = supplier.displayName;
        }
      });
    });
    setSupplierFullNameById(lookup);
  }, [pharmacies]);

  async function refreshPharmacies() {
    const token = await authService.getAccessTokenSilently();
    const { data } = await getPharmacies(token);
    console.log("refreshPharmacies", data);
    if (!data?.pharmacies) return;

    const newPharmacies = formatPharmacies(data.pharmacies);
    setPharmacies((prev) => {
      if (prev.length !== newPharmacies.length) {
        console.log("refreshPharmacies length mismatch");
        return newPharmacies;
      }

      for (let i = 0; i < prev.length; i++) {
        const prevPharmacy = prev[i];
        const currPharmacy = newPharmacies[i];
        if (prevPharmacy.id !== currPharmacy.id) {
          console.log("refreshPharmacies id mismatch");
          return newPharmacies;
        }

        const prevSuppliers = prevPharmacy.suppliers;
        const currSuppliers = currPharmacy.suppliers;
        if (prevSuppliers.length !== currSuppliers.length) {
          console.log("refreshPharmacies suppliers length mismatch");
          return newPharmacies;
        }

        for (let j = 0; j < prevSuppliers.length; j++) {
          const prevSupplier = prevSuppliers[j];
          const currSupplier = currSuppliers[j];
          if (!deepEqual(prevSupplier, currSupplier)) {
            console.log("refreshPharmacies supplier data mismatch", prevSupplier, currSupplier);
            return newPharmacies;
          }
        }
      }
      console.log("refreshPharmacies no change");
      return prev;
    });

    return newPharmacies;
  }

  return (
    <PharmaciesContext.Provider
      value={{ pharmacies, pharmaciesLoaded, supplierFullNameById, refreshPharmacies }}
    >
      {children}
    </PharmaciesContext.Provider>
  );
}
