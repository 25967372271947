import { useState, useEffect } from "react";
import { authService } from "../../../libs/Authentication";
import { displayErrorModal } from "../../../utilities/errorModals/displayErrorModal";
import { BackendResponse } from "../../../services/legacy/config/backend-api";

export function useListTable<T extends Record<string, any>>({
  name,
  fetchList,
}: {
  name: string;
  fetchList: (token: string) => Promise<BackendResponse<Record<string, T[]>>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<T[]>();

  useEffect(() => {
    init();

    async function init() {
      setIsLoading(true);
      try {
        const token = await authService.getAccessTokenSilently();
        const response = await fetchList(token);
        const responseData = response.data?.[name];
        if (!responseData) throw new Error();

        setList(responseData);
      } catch (error) {
        displayErrorModal({ title: "Error fetching table data" });
      }
      setIsLoading(false);
    }
  }, [fetchList]);

  return { list, isLoading };
}
