import type { DrugWithStats, Prescription } from "../types";
import { getDirectDeal } from "./getDirectDeal";

export function getInitialPackSize(
  item: Prescription | DrugWithStats
): boolean {
  const directDeal = getDirectDeal(item);

  if (directDeal) return false;
  return true;
}
