import React, { useCallback, useEffect, ReactNode } from "react";
import { Tooltip } from "antd";
import { openChat } from "../../../../utilities/chat/openChat";
import { beep } from "../../../../utilities/sound/beep";
import { Button } from "../../../../components/rxLibrary/buttons";
import { Text } from "../../../../components/rxLibrary/typography";
import { IconBell } from "../../../../components/rxLibrary/icons";
import { ShoppingRxPrescriptionModalTooltipErrorEnum } from "./ShoppingRxPrescriptionModal.constants";

function ShoppingRxPrescriptionModalTooltipErrorContent({
  errorType,
  close,
}: {
  errorType: string;
  close: () => void;
}) {
  useEffect(() => {
    const shouldBeep =
      errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.SEARCHING ||
      errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.NO_RX_NUMBER;

    if (shouldBeep) beep().catch(console.error);
    document.addEventListener("keydown", close);
    const timeoutId = setTimeout(close, 4000);

    return () => {
      document.removeEventListener("keydown", close);
      clearTimeout(timeoutId);
    };
  }, []);

  function renderErrorContent() {
    if (errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.DUPLICATED) {
      return (
        <Text block center>
          This Rx is already on the list.
        </Text>
      );
    }

    if (
      errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.NO_RX_NUMBER
    ) {
      return (
        <Text block>
          This is not an Rx Number.
          <br />
          If your label has multiple barcodes, one of them will be the Rx
          number.
        </Text>
      );
    }

    return (
      <>
        <Text block>
          {"Something went wrong - your item wasn't added to the list."}
          <br />
          1. Try adding again
          <br />
          2. If this doesn’t work, please
        </Text>

        <Button tw="mb-[20px]" variant="text-3" onClick={openChat} block>
          Chat with Us
        </Button>

        <Button tw="mx-auto" variant="text-1" onClick={close} block>
          OK
        </Button>
      </>
    );
  }

  return (
    <div tw="w-[220px] p-[20px] pb-[10px]">
      <IconBell tw="mx-auto mb-[7px]" color="red-1" />
      {renderErrorContent()}
    </div>
  );
}

export function ShoppingRxPrescriptionModalTooltipError({
  isOpen,
  children,
  errorType,
  onClose,
  setTooltipError,
}: {
  isOpen: boolean;
  children: ReactNode;
  errorType?: string;
  setTooltipError: (error?: string) => void;
  onClose?: () => void;
}) {
  const closeTooltipError = useCallback(() => {
    setTooltipError(undefined);
    onClose?.();
  }, [onClose, setTooltipError]);

  const handleOpenChangeTooltipError = useCallback(
    (open: boolean) => !open && closeTooltipError(),
    [closeTooltipError]
  );

  if (!isOpen || !errorType) return <>{children}</>;
  return (
    <Tooltip
      color="white"
      trigger="contextMenu"
      placement="bottom"
      onOpenChange={handleOpenChangeTooltipError}
      title={
        <ShoppingRxPrescriptionModalTooltipErrorContent
          errorType={errorType}
          close={closeTooltipError}
        />
      }
      open
    >
      {children}
    </Tooltip>
  );
}
