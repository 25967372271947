"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logIn = logIn;
exports.requestPasswordlessEmail = requestPasswordlessEmail;
exports.requestPasswordlessEmailError = requestPasswordlessEmailError;
exports.requestPasswordlessEmailSuccess = requestPasswordlessEmailSuccess;
exports.resendEmail = resendEmail;
exports.restart = restart;
exports.sendSMS = sendSMS;
exports.toggleTermsAcceptance = toggleTermsAcceptance;
var _index = require("../../store/index");
var _actions = require("../../core/actions");
var _web_api = _interopRequireDefault(require("../../core/web_api"));
var c = _interopRequireWildcard(require("../../field/index"));
var l = _interopRequireWildcard(require("../../core/index"));
var _index4 = require("./index");
var _phone_number = require("../../field/phone_number");
var i18n = _interopRequireWildcard(require("../../i18n"));
var _captcha = require("../captcha");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function getErrorMessage(m, id, error) {
  var key = error.error;
  if (error.error === 'sms_provider_error' && (error.description || '').indexOf('(Code: 21211)') > -1) {
    key = 'bad.phone_number';
  }
  if (error.code === 'invalid_captcha') {
    var captchaConfig = l.passwordlessCaptcha(m);
    key = captchaConfig.get('provider') === 'recaptcha_v2' || captchaConfig.get('provider') === 'recaptcha_enterprise' || captchaConfig.get('provider') === 'hcaptcha' || captchaConfig.get('provider') === 'auth0_v2' || captchaConfig.get('provider') === 'friendly_captcha' || captchaConfig.get('provider') === 'arkose' ? 'invalid_recaptcha' : 'invalid_captcha';
  }
  return i18n.html(m, ['error', 'passwordless', key]) || i18n.html(m, ['error', 'passwordless', 'lock.fallback']);
}
function swapCaptchaAfterError(id, error) {
  var wasCaptchaInvalid = error && error.code === 'invalid_captcha';
  (0, _captcha.swapCaptcha)(id, _captcha.Flow.PASSWORDLESS, wasCaptchaInvalid);
}
function requestPasswordlessEmail(id) {
  (0, _actions.validateAndSubmit)(id, ['email'], function (m) {
    sendEmail(m, id, requestPasswordlessEmailSuccess, requestPasswordlessEmailError);
  });
}
function requestPasswordlessEmailSuccess(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = l.setSubmitting(m, false);
    return (0, _index4.setPasswordlessStarted)(m, true);
  });
}
function requestPasswordlessEmailError(id, error) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var errorMessage = getErrorMessage(m, id, error);
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
  swapCaptchaAfterError(id, error);
}
function resendEmail(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.resend);
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  sendEmail(m, id, resendEmailSuccess, resendEmailError);
}
function resendEmailSuccess(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setResendSuccess);
}
function resendEmailError(id, error) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setResendFailed);
}
function getPasswordlessConnectionName(m, defaultPasswordlessConnection) {
  var connections = l.connections(m, 'passwordless', defaultPasswordlessConnection);
  return connections.size > 0 && l.useCustomPasswordlessConnection(m) ? connections.first().get('name') : defaultPasswordlessConnection;
}
function sendEmail(m, id, successFn, errorFn) {
  var params = {
    connection: getPasswordlessConnectionName(m, 'email'),
    email: c.getFieldValue(m, 'email'),
    send: (0, _index4.send)(m)
  };
  if ((0, _index4.isSendLink)(m) && !l.auth.params(m).isEmpty()) {
    params.authParams = l.auth.params(m).toJS();
  }
  var isCaptchaValid = (0, _captcha.setCaptchaParams)(m, params, _captcha.Flow.PASSWORDLESS, []);
  if (!isCaptchaValid) {
    return (0, _captcha.showMissingCaptcha)(m, id, true);
  }
  _web_api.default.startPasswordless(l.id(m), params, function (error) {
    if (error) {
      setTimeout(function () {
        return errorFn(l.id(m), error);
      }, 250);
    } else {
      successFn(l.id(m));
    }
  });
}
function sendSMS(id) {
  (0, _actions.validateAndSubmit)(id, ['phoneNumber'], function (m) {
    var params = {
      connection: getPasswordlessConnectionName(m, 'sms'),
      phoneNumber: (0, _phone_number.phoneNumberWithDiallingCode)(m),
      send: (0, _index4.send)(m)
    };
    var isCaptchaValid = (0, _captcha.setCaptchaParams)(m, params, _captcha.Flow.PASSWORDLESS, []);
    if (!isCaptchaValid) {
      return (0, _captcha.showMissingCaptcha)(m, id, true);
    }
    _web_api.default.startPasswordless(id, params, function (error) {
      if (error) {
        setTimeout(function () {
          return sendSMSError(id, error);
        }, 250);
      } else {
        sendSMSSuccess(id);
      }
    });
  });
}
function sendSMSSuccess(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = l.setSubmitting(m, false);
    m = (0, _index4.setPasswordlessStarted)(m, true);
    return m;
  });
}
function sendSMSError(id, error) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var errorMessage = getErrorMessage(m, id, error);
  l.emitAuthorizationErrorEvent(m, error);
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
  swapCaptchaAfterError(id, error);
}
function logIn(id) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var authParams = l.auth.params(m).toJS();
  var params = _objectSpread({
    verificationCode: c.getFieldValue(m, 'vcode')
  }, authParams);
  if ((0, _index4.isEmail)(m)) {
    params.connection = getPasswordlessConnectionName(m, 'email');
    params.email = c.getFieldValue(m, 'email');
  } else {
    params.connection = getPasswordlessConnectionName(m, 'sms');
    params.phoneNumber = (0, _phone_number.phoneNumberWithDiallingCode)(m);
  }
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, true);
  _web_api.default.passwordlessVerify(id, params, function (error, result) {
    var errorMessage;
    if (error) {
      var _m = (0, _index.read)(_index.getEntity, 'lock', id);
      errorMessage = getErrorMessage(_m, id, error);
      if (error.logToConsole) {
        console.error(error.description);
      }
      l.emitAuthorizationErrorEvent(_m, error);
      return (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
    } else {
      return (0, _actions.logInSuccess)(id, result);
    }
  });
}
function restart(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.restartPasswordless);
  (0, _captcha.swapCaptcha)(id, _captcha.Flow.PASSWORDLESS, false);
}
function toggleTermsAcceptance(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.toggleTermsAcceptance);
}
