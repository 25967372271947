import React, { useCallback, useEffect } from "react";
import "twin.macro";
import { AgGridReact } from "ag-grid-react";
import type { GridReadyEvent, ICellRendererParams } from "ag-grid-community";
import type { UseReportReturn } from "./useReport/useReport";
import { Text, Title } from "../../../../../components/rxLibrary/typography";
import { getMainMenuItems } from "./getMainMenuItems";

export function useDetailReports({
  api,
  node,
}: Pick<ICellRendererParams, "node" | "api">) {
  const rowId = node.id as string;

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      const gridInfo = {
        id: rowId,
        api: event.api,
        columnApi: event.columnApi,
      };
      api.addDetailGridInfo(rowId, gridInfo);
    },
    [api]
  );

  useEffect(() => {
    return () => {
      api.removeDetailGridInfo(rowId);
    };
  }, []);

  return { onGridReady };
}

export function DetailReports({
  data,
  reports,
}: {
  data: Record<string, any>;
  reports: {
    key: string;
    title: string;
    report: UseReportReturn;
  }[];
}) {
  return (
    <div tw="relative px-6 space-y-4">
      {reports.map(({ report, title, key }) => {
        const rowData = data[key];

        return (
          <div key={key} tw="space-y-2">
            <Title variant="h2" block>
              {title}
            </Title>

            {rowData ? (
              <AgGridReact
                className="ag-theme-alpine"
                tw="block min-h-[420px]! h-[420px]!"
                {...report}
                rowData={rowData}
                getMainMenuItems={getMainMenuItems}
              />
            ) : (
              <div tw="border flex justify-center items-center mt-4 mb-12 h-[200px]">
                <Text tw="mt-4 mb-12" size="large">
                  No Data
                </Text>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
