import { useCallback } from "react";
import axios, { AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { authService } from "../../libs/Authentication";
import { X_CLIENT_SESSION_ID } from "../../utilities/config";
import { snakeCaseObjectKeys } from "../../utilities/objects/snakeCaseObjectKeys";
import { paramsSerializer } from "../../utilities/queryParams/paramsSerializer";

const { REACT_APP_BACKEND_URL } = process.env;

/**
 * @property {number=} retries - quantity of additional retries to the original request
 **/
type RequestConfig = Omit<
  AxiosRequestConfig,
  "baseUrl" | "paramsSerializer"
> & {
  noAuth?: boolean;
  retries?: number;
  noDataWrapper?: boolean;
  noSnakeCaseParams?: boolean;
  shouldSkipSentryNotification?: (error: any) => boolean;
};

export type RequestClient = ReturnType<typeof useRequestClient>;

export type RequestClientConfig<C = Record<string, never>> = RequestConfig & C;

class RequestError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = "RequestError";
  }
}

/**
 * Hook to create a request client that automatically adds the necessary headers and snake cases the params
 */
export function useRequestClient() {
  const client = useCallback(
    async <RequestPayload = void>({
      retries,
      noAuth = false,
      noDataWrapper = false,
      noSnakeCaseParams = false,
      shouldSkipSentryNotification = () => false,
      ...options
    }: RequestConfig & { url: string }) => {
      const config: AxiosRequestConfig = {
        ...options,
        method: options.method ?? "GET",
        baseURL: REACT_APP_BACKEND_URL,
        paramsSerializer,
      };
      config.headers = {
        ...config.headers,
        "X-Client-Session-Id": X_CLIENT_SESSION_ID,
        "X-Client-Request-Id": uuidv4(),
      };

      if (!noSnakeCaseParams) {
        config.params = snakeCaseObjectKeys(config.params);
      }

      if (!noAuth) {
        const token = await authService.getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (!noDataWrapper && config.data) {
        config.data = { data: config.data };
      }

      const client = axios.create();
      if (retries) {
        axiosRetry(client, {
          retries,
          onRetry: (retryCount, error, requestConfig) => {
            console.warn(
              `Request ${requestConfig.method} ${requestConfig.url} failed with status ${error?.response?.status}. Retrying ${retryCount} of ${retries}, error `,
              error,
              " config ",
              config
            );
          },
        });
      }

      type Response = RequestPayload extends Blob
        ? Blob
        : RequestPayload extends void
        ? void
        : { data: RequestPayload };
      return client<Response>(config).catch((error) => {
        const isCancelled = axios.isCancel(error);
        let msg = `Request ${config.method} ${config.url} `;
        msg += isCancelled ? "cancelled" : "failed";
        if (error?.response?.status) {
          msg += ` with status ${error.response.status}`;
        }

        if (isCancelled) {
          console.warn(msg, error);
          return;
        }

        const skipSentryNotification = shouldSkipSentryNotification(error);
        if (skipSentryNotification) {
          console.warn(msg, error);
        } else {
          console.error(msg, error);
          Sentry.captureException(new RequestError(msg), {
            extra: {
              requestRetires: retries,
              requestConfig: config,
              responseData: error.response,
              response: error.response?.data,
              error,
              stringifiedError: JSON.stringify(error, null, 2),
            },
          });
        }

        throw error;
      });
    },
    []
  );

  return client;
}
