import React, { useMemo } from "react";
import type { DirectDeal } from "../../utilities/types";
import { roundDirectDealDiscountPercentage } from "../../utilities/prescriptions/roundDirectDealDiscountPercentage";
import { Text } from "../rxLibrary/typography";
import { Box } from "../rxLibrary/box";

function Tags({
  hideNotes,
  directDeal,
  hideNotice,
  packQuantity,
  descriptiveNotice,
}: {
  directDeal: DirectDeal;
} & Omit<Parameters<typeof ManufacturerDirectDeal>[0], "directDeal">) {
  const { discountPercent, bundleSize } = directDeal;
  const notes = hideNotes ? undefined : directDeal.notes;

  const notice = useMemo(() => {
    if (hideNotice || bundleSize === 1 || packQuantity === 0) return;

    if (packQuantity < bundleSize) return `Buy in bundles of ${bundleSize}`;

    if (packQuantity % bundleSize === 0) {
      if (descriptiveNotice) return `For bundles of ${bundleSize}`;
      return;
    }

    if (descriptiveNotice) {
      return `Quantity of ${bundleSize} qualifies. The rest of your order will be directed to other suppliers`;
    }

    return `For ${bundleSize} packs of ${packQuantity}`;
  }, [bundleSize, hideNotice, descriptiveNotice, packQuantity]);

  const [tag, isTagBgRed] = useMemo(() => {
    const formattedDiscountPercent =
      roundDirectDealDiscountPercentage(discountPercent);
    if (bundleSize === 1 || packQuantity === 0) {
      return [`WAC -${formattedDiscountPercent}% Deal`];
    }
    if (packQuantity % bundleSize === 0) {
      return [`WAC -${formattedDiscountPercent}% on bundles of ${bundleSize}`];
    }
    return [`Don't miss WAC -${formattedDiscountPercent}% Deal`, true];
  }, [bundleSize, packQuantity, discountPercent]);

  return (
    <>
      <Box
        tw="inline-block rounded-[3px] py-[1px] px-[7px]"
        bgColor={isTagBgRed ? "red-1" : "grey-1"}
      >
        <Text size={12} weight="bold" color="white-1" block>
          {tag}
        </Text>
      </Box>

      {!!notice && (
        <Text size={12} block>
          {notice}
        </Text>
      )}

      {!!notes && (
        <Text size={12} block>
          ({notes})
        </Text>
      )}
    </>
  );
}

export function ManufacturerDirectDeal({
  directDeal,
  ...props
}: {
  directDeal?: DirectDeal;
  packQuantity: number;
  hideNotes?: boolean;
  hideNotice?: boolean;
  descriptiveNotice?: boolean;
}) {
  if (!directDeal || directDeal.discountPercent === 0) return null;
  return <Tags {...props} directDeal={directDeal} />;
}
