import type { DirectDeal } from "../types";

export function getDirectDeal({
  directDeals,
}: {
  directDeals?: DirectDeal[];
}) {
  const directDeal = directDeals?.[0];
  if (!!directDeal && directDeal.discountPercent > 0) return directDeal;
}
