import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  expandHereValueGetter,
  manufacturerReportPercentFormatter,
} from "../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Fill Details",
    valueGetter: (params) => {
      if (params?.data?.pinnedRow) return "Total";
      return expandHereValueGetter();
    },
    cellRenderer: "agGroupCellRenderer",
    pinned: "left",
  },
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", pinned: "left" },
      { field: "Pharmacy NPI", pinned: "left", hide: true },
    ],
  },
  {
    headerName: "Pharmacy Summary",
    children: [
      { field: "Program Start Date" },
      {
        field: "Non-Adherent Refill Opportunities",
        headerName: "Non-Adherent Refill Opportunities",
        sort: "desc",
      },
      {
        field: "Attempted Outreaches (of Non-Adherent Refill Opportunity)",
        headerName:
          "Attempted Outreaches (of Non-Adherent Refill Opportunities)",
      },
      {
        field: "Unattempted Outreaches (of Non-Adherent Refill Opportunity)",
        headerName:
          "Unattempted Outreaches (of Non-Adherent Refill Opportunity)",
        hide: true,
      },
      {
        field: "% Attempted (of Non-Adherent Refill Opportunity)",
        headerName: "% Attempted (of Non-Adherent Refill Opportunities)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "Abandoned/Transferred Refills",
        headerName: "Abandoned/Transferred Refills",
        hide: true,
      },
      {
        field: "In-Progress Refills",
        headerName: "In-Progress Refills",
        hide: true,
      },
      {
        field: "Dispensed Refills",
        headerName: "Dispensed Refills",
        aggFunc: "sum",
      },
      {
        field: "% Dispensed of Attempted",
        headerName: "% Dispensed of Attempted",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "% Dispensed of Opportunities",
        headerName: "% Dispensed of Non-Adherent Refill Opportunities",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "% In Progress of Opportunities",
        headerName: "% In-Progress of Non-Adherent Refill Opportunities",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
    ],
  },
];
