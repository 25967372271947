import { useCallback } from "react";
import type { Prescription } from "../types";
import { getPrescriptions, getRxes } from "../../services/prescriptions";
import { useRequestClient } from "../../services/request/requestClient";
import { authService } from "../../libs/Authentication";
import { ItemTypeEnum } from "./itemType/itemTypeEnum";

export function useFetchPrescriptions() {
  const client = useRequestClient();

  const getPmsPrescriptions = useCallback(
    async (pharmacyId: number, useCache: boolean): Promise<Prescription[]> => {
      const token = await authService.getAccessTokenSilently();
      const { data } = await getPrescriptions(pharmacyId, token, useCache);
      const { prescriptions } = data ?? {};
      if (!prescriptions) return [];

      const prescriptionsWithType = prescriptions.map((item) => ({
        ...item,
        itemType: ItemTypeEnum.PMS,
      }));
      return prescriptionsWithType;
    },
    []
  );

  const getPrescriptionsByRxNumber = useCallback(
    async (
      pharmacyId: number,
      rxNumbers: string[]
    ): Promise<Prescription[]> => {
      if (!rxNumbers.length) return [];

      const response = await getRxes(client, {
        rx: rxNumbers,
        pharmacyId,
      });
      const { prescriptions } = response?.data?.data ?? {};
      if (!prescriptions || !Array.isArray(prescriptions)) return [];
      const prescriptionsWithType = prescriptions.map((item) => ({
        ...item,
        itemType: ItemTypeEnum.NoPMS,
      }));
      return prescriptionsWithType;
    },
    [client]
  );

  const getAllPrescriptions = useCallback(
    async ({
      pharmacyId,
      rxNumbers,
      useCache = false,
    }: {
      pharmacyId: number;
      rxNumbers: string[];
      useCache?: boolean;
    }) => {
      const [pmsPrescriptions, noPmsPrescriptions] = await Promise.all([
        getPmsPrescriptions(pharmacyId, useCache),
        getPrescriptionsByRxNumber(pharmacyId, rxNumbers),
      ]);

      const noPmsRxNumberSet = new Set(
        noPmsPrescriptions.map((i) => i.rxNumber)
      );
      const onlyPmsPrescriptions = pmsPrescriptions.filter(
        (item) => !noPmsRxNumberSet.has(item.rxNumber)
      );

      const allPrescriptions = [...onlyPmsPrescriptions, ...noPmsPrescriptions];
      return allPrescriptions;
    },
    [getPmsPrescriptions, getPrescriptionsByRxNumber]
  );

  return { getAllPrescriptions, getPrescriptionsByRxNumber };
}
