import { Switch } from "../../../components/rxLibrary/switch";
import { Text } from "../../../components/rxLibrary/typography";
import { PrescriptionTags } from "../../../components/shopping/PrescriptionTags/PrescriptionTags";
import { PrescriptionQuantityItem } from "../../../utilities/types";

export function ChosenSubstitutions({
  prescription,
  updateChosenSubstitution,
}: {
  prescription: PrescriptionQuantityItem;
  updateChosenSubstitution: (
    rxNumbers: string[],
    allowPackSizeSubstitution: boolean,
    allowManufacturerSubstitution: boolean
  ) => void;
}) {
  const {
    rxList,
    rxNumbers,
    numPackages,
    noManufacturerPreference,
    allowPackSizeSubstitution,
    allowManufacturerSubstitution,
  } = prescription;

  return (
    <div tw="space-y-1">
      <label tw="flex items-center">
        <Switch
          checked={allowPackSizeSubstitution}
          onChange={(checked) => {
            updateChosenSubstitution(
              rxNumbers,
              checked,
              allowManufacturerSubstitution
            );
          }}
        />
        <Text tw="ml-1" weight="bold">
          Pack size
        </Text>
      </label>

      {!noManufacturerPreference && (
        <label tw="flex items-center">
          <Switch
            checked={allowManufacturerSubstitution}
            onChange={(checked) => {
              updateChosenSubstitution(
                rxNumbers,
                allowPackSizeSubstitution,
                checked
              );
            }}
          />
          <Text tw="ml-1" weight="bold">
            Manufacturer
          </Text>
        </label>
      )}

      <PrescriptionTags
        item={rxList[0]}
        packQuantity={numPackages}
        omitDawCode
      />
    </div>
  );
}
