import React, { useEffect, ReactNode } from "react";
// THIS MUST BE BEFORE import Pusher
import {pusherCompatibilityHack} from "../../utilities/pusherCompatibilityHack";
import Pusher, { Channel } from 'pusher-js';
import { X_CLIENT_SESSION_ID } from "../../utilities/config";
import {useBuyingPharmacy} from "../BuyingPharmacyContext";
import { backendPost } from "../../services/legacy/config/backend-api";
import { authService } from "../../libs/Authentication";

pusherCompatibilityHack();

const { REACT_APP_BACKEND_URL: root } = process.env;

// Pusher.logToConsole = true;

type ServerUpdateNotificationsContextType = {
  activePrescriptionsPulledEvent: number;
  activeCartUpdatedEvent: number;
}

const ServerUpdateNotificationsContext = React.createContext<ServerUpdateNotificationsContextType | undefined>(undefined);

export function ServerUpdateNotificationsContextProvider({ children }: { children: ReactNode }) {
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const [activePrescriptionsPulledEvent, setActivePrescriptionsPulledEvent] = React.useState<number>(1);
  const [activeCartUpdatedEvent, setActiveCartUpdatedEvent] = React.useState<number>(0);
  const [newPrescriptionsPulledEvent, setNewPrescriptionsPulledEvent] = React.useState<boolean>(false);
  const [newCartUpdatedEvent, setNewCartUpdatedEvent] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!currentBuyingPharmacy) return;
    let channel: Channel | null = null;
    let pusher: Pusher | null = null;
    (async() => {
      pusher = new Pusher('7f9af53c903708836f87', {
        cluster: 'us2',
        channelAuthorization: {
          endpoint: `${root}/pusher/auth`,
          transport: 'ajax',
          customHandler: async (params, callback) => {
            const token = await authService.getAccessTokenSilently();
            const response = await backendPost("/pusher/auth?socket_id=" + params.socketId + "&channel_name=" + params.channelName, "", {
              method: "POST",
              headers: {
                Authorization: "Bearer " + token,
              }
            })
            if (response.ok) {
              const pusherToken = await response.json()
              callback(null, pusherToken);
            } else {
              const responseText = await response.text();
              callback(new Error("Authorization Error:" + responseText), null);
            }
          }
        },
      });
      // setPusher(pusher);
      channel = pusher.subscribe(currentBuyingPharmacy.cartNotificationsChannel);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      channel.bind('prescriptions_pulled', function(data: any) {
        if (data.originatingClientSessionId === X_CLIENT_SESSION_ID) {
          // console.log("prescriptions_pulled event from this client, ignoring")
          return;
        }
        console.log('prescriptions_pulled', data);
        setNewPrescriptionsPulledEvent(true);
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      channel.bind('cart_updated', function(data: any) {
        if (data.originatingClientSessionId === X_CLIENT_SESSION_ID) {
          // console.log("cart_updated event from this client, ignoring")
          return;
        }
        console.log('cart_updated', data);
        setNewCartUpdatedEvent(true);
      });

    })();
    return () => {
      console.log("UNSUBSCRIBING FROM PUSH NOTIFICATIONS", channel, pusher);
      channel?.unbind('cart_updated');
      channel?.unbind('prescriptions_pulled');
      pusher?.disconnect();
    }
  }, [currentBuyingPharmacy, setNewCartUpdatedEvent, setNewPrescriptionsPulledEvent]);

  useEffect(() => {
    if (newCartUpdatedEvent) {
      setActiveCartUpdatedEvent((prev) => prev + 1);
      setNewCartUpdatedEvent(false);
    }
  }, [newCartUpdatedEvent, setNewCartUpdatedEvent, setActiveCartUpdatedEvent])

  useEffect(() => {
    if (newPrescriptionsPulledEvent) {
      setActivePrescriptionsPulledEvent((prev) => prev + 1);
      setNewPrescriptionsPulledEvent(false);
    }
  }, [newPrescriptionsPulledEvent, setNewPrescriptionsPulledEvent, setActivePrescriptionsPulledEvent])

  return (
    <ServerUpdateNotificationsContext.Provider value={{
      activePrescriptionsPulledEvent,
      activeCartUpdatedEvent,
    }}>
      {children}
    </ServerUpdateNotificationsContext.Provider>
  )
}


export const useServerUpdateNotifications = () => {
  const context = React.useContext(ServerUpdateNotificationsContext);
  if (context === undefined) {
    throw new Error(
      "useServerUpdateNotifications must be used within a ServerUpdateNotificationsContextProvider"
    );
  }
  return context;
}
