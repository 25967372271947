import type { Prescription, DrugWithStats } from "../types";
import { getDirectDeal } from "./getDirectDeal";

export function getInitialManufacturer(
  item: Prescription | DrugWithStats
): boolean {
  const { isBrand, isNarrowTherapeuticIndex } = item.drug;
  const directDeal = getDirectDeal(item);
  
  if (isBrand || isNarrowTherapeuticIndex || directDeal) return false;
  return true;
}
