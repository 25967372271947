"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.additionalSignUpFields = additionalSignUpFields;
exports.authWithUsername = authWithUsername;
exports.availableScreens = availableScreens;
exports.databaseConnection = databaseConnection;
exports.databaseConnectionName = databaseConnectionName;
exports.databaseConnectionRequiresUsername = databaseConnectionRequiresUsername;
exports.databaseLogInWithEmail = databaseLogInWithEmail;
exports.databaseUsernameStyle = databaseUsernameStyle;
exports.databaseUsernameValue = databaseUsernameValue;
exports.defaultDatabaseConnection = defaultDatabaseConnection;
exports.defaultDatabaseConnectionName = defaultDatabaseConnectionName;
exports.forgotPasswordLink = forgotPasswordLink;
exports.getInitialScreen = getInitialScreen;
exports.getScreen = getScreen;
exports.hasInitialScreen = hasInitialScreen;
exports.hasScreen = hasScreen;
exports.initDatabase = initDatabase;
exports.mustAcceptTerms = mustAcceptTerms;
exports.overrideDatabaseOptions = overrideDatabaseOptions;
exports.passwordStrengthPolicy = passwordStrengthPolicy;
exports.resolveAdditionalSignUpFields = resolveAdditionalSignUpFields;
exports.setScreen = setScreen;
exports.shouldAutoLogin = shouldAutoLogin;
exports.showTerms = showTerms;
exports.signUpFieldsStrictValidation = signUpFieldsStrictValidation;
exports.signUpHideUsernameField = signUpHideUsernameField;
exports.signUpLink = signUpLink;
exports.termsAccepted = termsAccepted;
exports.toggleTermsAcceptance = toggleTermsAcceptance;
var _immutable = _interopRequireWildcard(require("immutable"));
var l = _interopRequireWildcard(require("../../core/index"));
var _index2 = require("../../field/index");
var _data_utils = require("../../utils/data_utils");
var _sync = _interopRequireDefault(require("../../sync"));
var _trim = _interopRequireDefault(require("trim"));
var _tenant = require("../../core/tenant");
var _enterprise = require("../../connection/enterprise");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _dataFns = (0, _data_utils.dataFns)(['database']),
  get = _dataFns.get,
  initNS = _dataFns.initNS,
  tget = _dataFns.tget,
  tset = _dataFns.tset;
function initDatabase(m, options) {
  m = initNS(m, _immutable.default.fromJS(processDatabaseOptions(options)));
  m = resolveAdditionalSignUpFields(m);
  return m;
}
function assertMaybeBoolean(opts, name) {
  var valid = opts[name] === undefined || typeof opts[name] === 'boolean';
  if (!valid) l.warn(opts, "The `".concat(name, "` option will be ignored, because it is not a boolean."));
  return valid;
}
function assertMaybeEnum(opts, name, a) {
  var valid = opts[name] === undefined || a.indexOf(opts[name]) > -1;
  if (!valid) l.warn(opts, "The `".concat(name, "` option will be ignored, because it is not one of the following allowed values: ").concat(a.map(function (x) {
    return JSON.stringify(x);
  }).join(', '), "."));
  return valid;
}
function assertMaybeString(opts, name) {
  var valid = opts[name] === undefined || typeof opts[name] === 'string' && (0, _trim.default)(opts[name]).length > 0;
  if (!valid) l.warn(opts, "The `".concat(name, "` option will be ignored, because it is not a non-empty string."));
  return valid;
}
function assertMaybeArray(opts, name) {
  var valid = opts[name] === undefined || window.Array.isArray(opts[name]);
  if (!valid) l.warn(opts, "The `".concat(name, "` option will be ignored, because it is not an array."));
  return valid;
}
function processDatabaseOptions(opts) {
  var additionalSignUpFields = opts.additionalSignUpFields,
    defaultDatabaseConnection = opts.defaultDatabaseConnection,
    forgotPasswordLink = opts.forgotPasswordLink,
    loginAfterSignUp = opts.loginAfterSignUp,
    mustAcceptTerms = opts.mustAcceptTerms,
    showTerms = opts.showTerms,
    signUpLink = opts.signUpLink,
    usernameStyle = opts.usernameStyle,
    signUpFieldsStrictValidation = opts.signUpFieldsStrictValidation,
    signUpHideUsernameField = opts.signUpHideUsernameField;
  var _processScreenOptions = processScreenOptions(opts),
    initialScreen = _processScreenOptions.initialScreen,
    screens = _processScreenOptions.screens;
  if (!assertMaybeEnum(opts, 'usernameStyle', ['email', 'username'])) {
    usernameStyle = undefined;
  }
  if (!assertMaybeString(opts, 'defaultDatabaseConnection')) {
    defaultDatabaseConnection = undefined;
  }
  if (!assertMaybeString(opts, 'forgotPasswordLink')) {
    forgotPasswordLink = undefined;
  }
  if (!assertMaybeString(opts, 'signUpLink')) {
    signUpLink = undefined;
  }
  if (!assertMaybeBoolean(opts, 'mustAcceptTerms')) {
    mustAcceptTerms = undefined;
  }
  if (!assertMaybeBoolean(opts, 'showTerms')) {
    showTerms = true;
  }
  if (!assertMaybeBoolean(opts, 'signUpFieldsStrictValidation')) {
    signUpFieldsStrictValidation = false;
  }
  if (!assertMaybeBoolean(opts, 'signUpHideUsernameField')) {
    signUpHideUsernameField = false;
  }
  if (!assertMaybeArray(opts, 'additionalSignUpFields')) {
    additionalSignUpFields = undefined;
  } else if (additionalSignUpFields) {
    additionalSignUpFields = additionalSignUpFields.reduce(function (r, x) {
      var icon = x.icon,
        name = x.name,
        options = x.options,
        placeholder = x.placeholder,
        placeholderHTML = x.placeholderHTML,
        prefill = x.prefill,
        type = x.type,
        validator = x.validator,
        value = x.value,
        storage = x.storage;
      var filter = true;
      var reservedNames = ['email', 'username', 'password'];
      if (typeof name != 'string' || !name.match(/^[a-zA-Z0-9_]+$/) || reservedNames.indexOf(name) > -1) {
        l.warn(opts, "Ignoring an element of `additionalSignUpFields` because it does not contain valid `name` property. Every element of `additionalSignUpFields` must be an object with a `name` property that is a non-empty string consisting of letters, numbers and underscores. The following names are reserved, and therefore, cannot be used: ".concat(reservedNames.join(', '), "."));
        filter = false;
      }
      if (type !== 'hidden' && (typeof placeholder != 'string' || !placeholder) && (typeof placeholderHTML != 'string' || !placeholderHTML)) {
        l.warn(opts, "Ignoring an element of `additionalSignUpFields` (".concat(name, ") because it does not contain a valid `placeholder` or `placeholderHTML` property. Every element of `additionalSignUpFields` must have a `placeholder` or `placeholderHTML` property that is a non-empty string."));
        filter = false;
      }
      if (placeholderHTML && placeholder) {
        l.warn(opts, 'When provided, the `placeholderHTML` property of an element of `additionalSignUpFields` will override the `placeholder` property of that element');
      }
      if (icon != undefined && (typeof icon != 'string' || !icon)) {
        l.warn(opts, 'When provided, the `icon` property of an element of `additionalSignUpFields` must be a non-empty string.');
        icon = undefined;
      }
      if (prefill != undefined && (typeof prefill != 'string' || !prefill) && typeof prefill != 'function') {
        l.warn(opts, 'When provided, the `prefill` property of an element of `additionalSignUpFields` must be a non-empty string or a function.');
        prefill = undefined;
      }
      var types = ['select', 'text', 'checkbox', 'hidden'];
      if (type != undefined && (typeof type != 'string' || types.indexOf(type) === -1)) {
        l.warn(opts, "When provided, the `type` property of an element of `additionalSignUpFields` must be one of the following strings: \"".concat(types.join('", "'), "\"."));
        type = undefined;
      }
      if (validator != undefined && type === 'select') {
        l.warn(opts, 'Elements of `additionalSignUpFields` with a "select" `type` cannot specify a `validator` function, all of its `options` are assumed to be valid.');
        validator = undefined;
      }
      if (validator != undefined && typeof validator != 'function') {
        l.warn(opts, 'When provided, the `validator` property of an element of `additionalSignUpFields` must be a function.');
        validator = undefined;
      }
      if (options != undefined && type != 'select') {
        l.warn(opts, 'The `options` property can only by provided for an element of `additionalSignUpFields` when its `type` equals to "select"');
        options = undefined;
      }
      if (options != undefined && !window.Array.isArray(options) && typeof options != 'function' || type === 'select' && options === undefined) {
        l.warn(opts, "Ignoring an element of `additionalSignUpFields` (".concat(name, ") because it has a \"select\" `type` but does not specify an `options` property that is an Array or a function."));
        filter = false;
      }
      if (type === 'hidden' && !value) {
        l.warn(opts, "Ignoring an element of `additionalSignUpFields` (".concat(name, ") because it has a \"hidden\" `type` but does not specify a `value` string."));
        filter = false;
      }
      return filter ? r.concat([{
        icon: icon,
        name: name,
        options: options,
        placeholder: placeholder,
        placeholderHTML: placeholderHTML,
        prefill: prefill,
        type: type,
        validator: validator,
        value: value,
        storage: storage
      }]) : r;
    }, []);
    additionalSignUpFields = _immutable.default.fromJS(additionalSignUpFields).map(function (x) {
      return x.filter(function (y) {
        return y !== undefined;
      });
    });
  }

  // TODO: add a warning if it is not a boolean, leave it undefined,
  // and change accessor fn.
  loginAfterSignUp = loginAfterSignUp === false ? false : true;
  return (0, _immutable.Map)({
    additionalSignUpFields: additionalSignUpFields,
    defaultConnectionName: defaultDatabaseConnection,
    forgotPasswordLink: forgotPasswordLink,
    initialScreen: initialScreen,
    loginAfterSignUp: loginAfterSignUp,
    mustAcceptTerms: mustAcceptTerms,
    showTerms: showTerms,
    screens: screens,
    signUpLink: signUpLink,
    usernameStyle: usernameStyle,
    signUpFieldsStrictValidation: signUpFieldsStrictValidation,
    signUpHideUsernameField: signUpHideUsernameField
  }).filter(function (x) {
    return typeof x !== 'undefined';
  }).toJS();
}
function processScreenOptions(opts) {
  var defaults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    allowLogin: true,
    allowSignUp: true,
    allowForgotPassword: true,
    initialScreen: undefined
  };
  var allowForgotPassword = opts.allowForgotPassword,
    allowLogin = opts.allowLogin,
    allowSignUp = opts.allowSignUp,
    initialScreen = opts.initialScreen;
  var screens = [];
  if (allowLogin === true || !assertMaybeBoolean(opts, 'allowLogin') && defaults.allowLogin || allowLogin === undefined && defaults.allowLogin) {
    screens.push('login');
  }
  if (allowSignUp === true || !assertMaybeBoolean(opts, 'allowSignUp') && defaults.allowSignUp || allowSignUp === undefined && defaults.allowSignUp) {
    screens.push('signUp');
  }
  if (allowForgotPassword === true || !assertMaybeBoolean(opts, 'allowForgotPassword') && defaults.allowForgotPassword || allowForgotPassword === undefined && defaults.allowForgotPassword) {
    screens.push('forgotPassword');
  }
  screens.push('mfaLogin');
  if (!assertMaybeEnum(opts, 'initialScreen', screens)) {
    initialScreen = undefined;
  }
  if (initialScreen === undefined) {
    initialScreen = defaults.initialScreen || screens[0];
  }
  return {
    initialScreen: initialScreen,
    screens: new _immutable.List(screens)
  };
}
function overrideDatabaseOptions(m, opts) {
  var _processScreenOptions2 = processScreenOptions(opts, {
      allowLogin: availableScreens(m).contains('login'),
      allowSignUp: availableScreens(m).contains('signUp'),
      allowForgotPassword: availableScreens(m).contains('forgotPassword'),
      initialScreen: get(m, 'initialScreen')
    }),
    initialScreen = _processScreenOptions2.initialScreen,
    screens = _processScreenOptions2.screens;
  m = tset(m, 'initialScreen', initialScreen);
  m = tset(m, 'screens', screens);
  return m;
}
function defaultDatabaseConnection(m) {
  var name = defaultDatabaseConnectionName(m);
  return name && l.findConnection(m, name);
}
function defaultDatabaseConnectionName(m) {
  return get(m, 'defaultConnectionName');
}
function databaseConnection(m) {
  return l.resolvedConnection(m) || (0, _tenant.defaultDirectory)(m) || defaultDatabaseConnection(m) || l.connection(m, 'database');
}
function databaseConnectionName(m) {
  return (databaseConnection(m) || (0, _immutable.Map)()).get('name');
}
function forgotPasswordLink(m) {
  var notFound = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return get(m, 'forgotPasswordLink', notFound);
}
function signUpLink(m) {
  var notFound = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return get(m, 'signUpLink', notFound);
}
function setScreen(m, name) {
  var fields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  // TODO: the lock/index module should provide a way to clear
  // everything that needs the be cleared when changing screens, other
  // modules should not care.
  m = l.clearGlobalError(m);
  m = l.clearGlobalSuccess(m);
  m = (0, _index2.hideInvalidFields)(m, fields);
  m = (0, _index2.clearFields)(m, fields);
  return tset(m, 'screen', name);
}
function getScreen(m) {
  var screen = tget(m, 'screen');
  var initialScreen = getInitialScreen(m);
  var screens = [screen, initialScreen, 'login', 'signUp', 'forgotPassword', 'mfaLogin'];
  var availableScreens = screens.filter(function (x) {
    return hasScreen(m, x);
  });
  return availableScreens[0];
}
function availableScreens(m) {
  return tget(m, 'screens') || get(m, 'screens', new _immutable.List());
}
function getInitialScreen(m) {
  return tget(m, 'initialScreen') || get(m, 'initialScreen');
}
function hasInitialScreen(m, str) {
  return getInitialScreen(m) === str;
}
function databaseConnectionRequiresUsername(m) {
  return (databaseConnection(m) || (0, _immutable.Map)()).toJS().requireUsername;
}
function databaseUsernameStyle(m) {
  if (l.hasSomeConnections(m, 'database')) {
    if (l.connectionResolver(m)) {
      return 'username';
    }
    return databaseConnectionRequiresUsername(m) ? get(m, 'usernameStyle', 'any') : 'email';
  }
  return l.hasSomeConnections(m, 'enterprise') && (0, _enterprise.findADConnectionWithoutDomain)(m) ? 'username' : 'email';
}
function databaseLogInWithEmail(m) {
  return databaseUsernameStyle(m) === 'email';
}
function databaseUsernameValue(m) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var isEmailOnly = databaseLogInWithEmail(m);
  if (isEmailOnly) {
    return (0, _index2.getFieldValue)(m, 'email');
  }
  if (options.emailFirst) {
    return (0, _index2.getFieldValue)(m, 'email') || (0, _index2.getFieldValue)(m, 'username');
  }
  return (0, _index2.getFieldValue)(m, 'username') || (0, _index2.getFieldValue)(m, 'email');
}
function authWithUsername(m) {
  return databaseConnectionRequiresUsername(m) || get(m, 'usernameStyle', 'email') === 'username';
}
function hasScreen(m, s) {
  var _toJS = (databaseConnection(m) || (0, _immutable.Map)()).toJS(),
    allowForgot = _toJS.allowForgot,
    allowSignup = _toJS.allowSignup;
  return !(allowForgot === false && s === 'forgotPassword') && !(allowSignup === false && s === 'signUp') && availableScreens(m).contains(s);
}
function shouldAutoLogin(m) {
  return get(m, 'loginAfterSignUp');
}
function passwordStrengthPolicy(m) {
  return (databaseConnection(m) || (0, _immutable.Map)()).get('passwordPolicy', 'none');
}
function additionalSignUpFields(m) {
  return get(m, 'additionalSignUpFields', (0, _immutable.List)());
}
function showTerms(m) {
  return get(m, 'showTerms', true);
}
function signUpFieldsStrictValidation(m) {
  return get(m, 'signUpFieldsStrictValidation', false);
}
function signUpHideUsernameField(m) {
  return get(m, 'signUpHideUsernameField', false);
}
function mustAcceptTerms(m) {
  return get(m, 'mustAcceptTerms', false);
}
function termsAccepted(m) {
  return !mustAcceptTerms(m) || tget(m, 'termsAccepted', false);
}
function toggleTermsAcceptance(m) {
  return tset(m, 'termsAccepted', !termsAccepted(m));
}
function resolveAdditionalSignUpFields(m) {
  return additionalSignUpFields(m).reduce(function (r, x) {
    switch (x.get('type')) {
      case 'select':
        return resolveAdditionalSignUpSelectField(r, x);
      case 'hidden':
        return resolveAdditionalSignUpHiddenField(r, x);
      default:
        return resolveAdditionalSignUpTextField(r, x);
    }
  }, m);
}
function resolveAdditionalSignUpSelectField(m, x) {
  var name = x.get('name');
  var keyNs = ['additionalSignUpField', name];
  var prefill = x.get('prefill');
  var options = x.get('options');
  var resolvedPrefill = typeof prefill === 'function' ? undefined : prefill || '';
  var resolvedOptions = typeof options === 'function' ? undefined : options;
  var register = function register(m) {
    return resolvedPrefill !== undefined && resolvedOptions !== undefined ? (0, _index2.registerOptionField)(m, name, _immutable.default.fromJS(resolvedOptions), resolvedPrefill) : m;
  };
  if (resolvedPrefill === undefined) {
    m = (0, _sync.default)(m, keyNs.concat('prefill'), {
      recoverResult: '',
      successFn: function successFn(m, result) {
        resolvedPrefill = result;
        return register(m);
      },
      syncFn: function syncFn(m, cb) {
        return prefill(cb);
      }
    });
  }
  if (resolvedOptions === undefined) {
    m = (0, _sync.default)(m, keyNs.concat('options'), {
      successFn: function successFn(m, result) {
        resolvedOptions = result;
        return register(m);
      },
      syncFn: function syncFn(m, cb) {
        return options(cb);
      }
    });
  }
  if (resolvedPrefill !== undefined && resolvedOptions !== undefined) {
    m = (0, _index2.registerOptionField)(m, name, _immutable.default.fromJS(resolvedOptions), resolvedPrefill);
  }
  return m;
}
function resolveAdditionalSignUpTextField(m, x) {
  var name = x.get('name');
  var key = ['additionalSignUpField', name, 'prefill'];
  var prefill = x.get('prefill');
  var validator = x.get('validator');
  var resolvedPrefill = typeof prefill === 'function' ? undefined : prefill || '';
  if (resolvedPrefill === undefined) {
    m = (0, _sync.default)(m, key, {
      recoverResult: '',
      successFn: function successFn(m, result) {
        return (0, _index2.setField)(m, name, result, validator);
      },
      syncFn: function syncFn(m, cb) {
        return prefill(cb);
      }
    });
  } else {
    m = (0, _index2.setField)(m, name, resolvedPrefill, validator);
  }
  return m;
}
function resolveAdditionalSignUpHiddenField(m, x) {
  return (0, _index2.setField)(m, x.get('name'), x.get('value'));
}
