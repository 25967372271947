import { useState, useEffect, useCallback } from "react";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import intersection from "lodash/intersection";
import type { ItemInCart } from "../../../../utilities/types";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { findAndRemoveFromArray } from "../../../../utilities/arrays/findAndRemoveFromArray";

type SimilarItems = {
  ndc: string;
  inventory: ItemInCart[];
  prescriptions: ItemInCart[];
};

export function useSimilarItems({
  inventoryItems,
  prescriptionsItems,
}: {
  inventoryItems: ItemInCart[];
  prescriptionsItems: ItemInCart[];
}) {
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const [similarItems, setSimilarItems] = useState<SimilarItems[]>();
  const [excludedInventoryIds, setExcludedInventoryIds] = useState<string[]>();

  const removeSimilarItem = useCallback(
    (ndc: string) => {
      if (!similarItems) return;

      const [filteredSimilarItems, group] = findAndRemoveFromArray(
        similarItems,
        (group) => group.ndc === ndc
      );
      if (!group) return;

      const newSimilarItems = filteredSimilarItems?.length
        ? filteredSimilarItems
        : undefined;
      const inventoryIdToExclude = group.inventory.map(getPrescriptionId);

      setSimilarItems(newSimilarItems);
      setExcludedInventoryIds((prev = []) =>
        uniq([...prev, ...inventoryIdToExclude])
      );
    },
    [similarItems]
  );

  const addPrescriptionsToSimilarItems = useCallback(
    (newPrescriptions: ItemInCart[]) => {
      const prescriptions = [
        ...(similarItems?.flatMap((group) => group.prescriptions) || []),
        ...newPrescriptions,
      ];
      const excludedInventorySet = new Set(excludedInventoryIds);
      const noExcludedInventoryItems = inventoryItems.filter(
        (item) => !excludedInventorySet.has(getPrescriptionId(item))
      );

      const inventoryByNdc = groupBy(noExcludedInventoryItems, "drug.ndc");
      const prescriptionsByNdc = groupBy(prescriptions, "drug.ndc");
      const ndcs = intersection(
        Object.keys(inventoryByNdc),
        Object.keys(prescriptionsByNdc)
      );
      if (!ndcs.length) {
        setSimilarItems(undefined);
        return;
      }

      const newSimilarItems = ndcs.map((ndc): SimilarItems => {
        const inventory = inventoryByNdc[ndc];
        const prescriptions = prescriptionsByNdc[ndc];
        return { ndc, inventory, prescriptions };
      }, {});
      setSimilarItems(newSimilarItems);
    },
    [inventoryItems, similarItems]
  );

  useEffect(() => {
    if (!similarItems) return;

    const prescriptionsIdSet = new Set(
      prescriptionsItems.map((item) => getPrescriptionId(item))
    );
    const inventoryIdSet = new Set(
      inventoryItems.map((item) => getPrescriptionId(item))
    );

    const newSimilarItems = similarItems
      .map((group) => {
        const inventory = group.inventory.filter((item) => {
          return inventoryIdSet.has(getPrescriptionId(item));
        });
        const prescriptions = group.prescriptions.filter((item) => {
          return prescriptionsIdSet.has(getPrescriptionId(item));
        });
        return { ...group, inventory, prescriptions };
      })
      .filter((group) => group.inventory.length && group.prescriptions.length);

    if (!newSimilarItems.length) {
      setSimilarItems(undefined);
      return;
    }

    setSimilarItems((prev) => {
      if (isEqual(prev, newSimilarItems)) return prev;
      return newSimilarItems;
    });
  }, [inventoryItems, prescriptionsItems]);

  useEffect(() => {
    setSimilarItems(undefined);
    setExcludedInventoryIds(undefined);
  }, [pharmacyId]);

  return { similarItems, removeSimilarItem, addPrescriptionsToSimilarItems };
}
